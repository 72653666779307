import {api} from "../api/api";

const processData = async (formbody) => {
  try {

    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await api.post("/api/ProcessData", formbody, config );
    return response.data?.result?.result || [];
  } catch (error) {
    throw new Error(error?.response?.data?.title);
  }
};

export default processData;
