import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import Spinner from "../../components/spinner/spinner";

const Table = (props) => {
  const {
    content: data,
    columnsData: columns,
    tableStyle,
    paginatorTemplate,
    paginator,
    scrollable,
    rows,
    header,
    footer,
    rowsPerPageOptions,
    emptyMessage,
    currentPageReportTemplate,
    isCopyEnable,
  } = props;
  const [copyInProgress, setCopyInProgress] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    var table = document.querySelector(".copydatatable table");
    if (table) {
      copyTable("data");
    }
  });

  const copyTable = (type) => {
    if (type === "data") {
      var table = document.querySelector(".copydatatable table");
      if (navigator.clipboard && table) {
        var text = table.innerText;
        navigator.clipboard
          .writeText(text)
          .then((e) => {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: "Data Copied Successfully",
              life: 4000
            });
            setCopyInProgress(false);
          })
          .catch((e) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: e.message,
              life: 4000
            });
            setCopyInProgress(false);
          });
      }
    } else {
      setCopyInProgress(true);
    }
  };

  return (
    <>
    <Toast ref={toast} position="top-center" />
      {copyInProgress && <Spinner />}
      <DataTable
        value={data}
        tableStyle={tableStyle}
        paginatorTemplate={paginatorTemplate}
        paginator={paginator}
        scrollable={scrollable}
        className={`${tableStyle.dataTable}`}
        rows={rows}
        header={()=>header(copyTable, isCopyEnable, data)}
        footer={footer}
        rowsPerPageOptions={[10, 25, 50, 100, 150]}
        emptyMessage={emptyMessage}
        currentPageReportTemplate={currentPageReportTemplate}
      >
        {columns?.map((col, i) => (
          <Column
            key={col?.field}
            field={col?.field}
            header={col?.header}
            sortable={col?.sort}
            frozen={col?.frozen}
            style={{ minWidth: col?.minWidth }}
            headerStyle={{ minWidth: col.minWidth }}
            body={
              col?.columnType?.toLocaleLowerCase() === "fun"
                ? col.columnDefination
                : col?.field?.toLocaleLowerCase() === "errors"
                ? props?.createOrderErrorSplit
                : null
            }
          />
        ))}
      </DataTable>
      <>
        {isCopyEnable && copyInProgress && (
          <DataTable
          value={data}
          tableStyle={tableStyle}
          paginatorTemplate={paginatorTemplate}
          paginator={paginator}
          scrollable={scrollable}
          rows={rows}
          className={`${tableStyle.dataTable} copydatatable`}
          rowsPerPageOptions={rowsPerPageOptions}
          emptyMessage={emptyMessage}
          currentPageReportTemplate={currentPageReportTemplate}
        >
          {columns?.map((col, i) => (
            <Column
              key={col?.field}
              field={col?.field}
              header={col?.header}
              sortable={col?.sort}
              style={{ minWidth: col?.minWidth }}
              headerStyle={{ minWidth: col.minWidth }}
              body={
                col?.columnType?.toLocaleLowerCase() === "fun"
                ? col.columnDefination
                : col?.field?.toLocaleLowerCase() === "errors"
                ? props?.createOrderErrorSplit
                : null
              }
            />
          ))}
        </DataTable>
        )}
      </>
    </>
  );
};

export default Table;
