import axios from "axios";
import { useState, useEffect } from "react";
import { ConfirmDialog } from 'primereact/confirmdialog'; // Import ConfirmDialog from PrimeReact
import { useMsal } from "@azure/msal-react";
import  jwtDecode  from "jwt-decode";
import { loginRequest } from "../authConfig";
import { setaxiosheader } from "./api-client-factory"

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const api = axios;

const TokenExpiredInterceptor = (props) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { instance, accounts } = useMsal();

  useEffect(() => {

    const req_interceptor = api.interceptors.request.use(
      async(config) => {
        const token = config?.headers?.Authorization.substring(7).trim()
        const decodedToken = decodeToken(token);
        const minutesDifference = getTimeDifference(decodedToken);
        if (minutesDifference <= 15 && minutesDifference > 0) {
            await  getaccessToken();
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    )
    // Add response interceptor to check for token expiration
    const interceptor = api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          // Token expired, show confirmation box
          setShowConfirmDialog(true);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      // Clean up by ejecting the interceptor when component unmounts
      api.interceptors.response.eject(interceptor);
      api.interceptors.request.eject(req_interceptor);
    };
  }, []);

  const accept = () => {
    setShowConfirmDialog(false);
    // instance.logoutRedirect(
    //   { account: props?.accounts[0] }
    // );
    localStorage.clear();
    window.location.reload();
  };
  const decodeToken = (token)=>{
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken;
    } catch (error) {
      return null;
    }
  }
  const getTimeDifference = (decodedToken) => {
    const tokenExpDate = new Date(decodedToken.exp * 1000);
    const currentDate = new Date();
    let difference = tokenExpDate.getTime() - currentDate.getTime();
    const minutesDifference = Math.floor(difference / (1000 * 60)); // Convert to minutes
    return minutesDifference;
  }

  const getaccessToken = () => {
    const request = {
      ...loginRequest,
      account: props?.accounts[0],
      forceRefresh:true
    };
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        const bearer = `Bearer ${response.idToken}`;
        setaxiosheader(bearer)
        localStorage.setItem("token", response.idToken)
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          const bearer = `Bearer ${response.accessToken}`;
          setaxiosheader(bearer)
          // localStorage.clear();
        });
      });
  };
  return (
    <ConfirmDialog
      visible={showConfirmDialog}
      onHide={() => setShowConfirmDialog(false)}
      message="Session expired. Please save any unsaved data before refreshing or logging in. Click Ok to redirect to Login page or Cancel to stay back."
      header="Session Expired"
      icon="pi pi-exclamation-triangle"
      style={{ width: "50vw", color: "red", zIndex:"10000" }}
      breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
      accept={accept}
    />
  );
};

export { api, TokenExpiredInterceptor };
