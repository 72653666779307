"use client";

import { NavLink } from "react-router-dom";

import styles from "../sub-menu/subMenu.module.scss";

const SideSubmenu = (props) => {
  const { menuItem } = props;
  const { isMenuExpanded } = props;

  return (
    <>
      {menuItem?.menuItems?.map((item) => {
        return (
          <div className={styles.submenuContainer} key={item?.id}>
            <NavLink
              to={item?.path}
              className={({ isActive }) => (isActive ? ` ${styles.submenuItem} ${styles.active} ${!isMenuExpanded ? styles.submenuWithOutPadding : "" }` : `${styles.submenuItem} ${!isMenuExpanded ? styles.submenuWithOutPadding : "" }`)}
              activeclassname="active"
            >
              {item?.submenu}
            </NavLink>
          </div>
        );
      })}
    </>
  );
};

export default SideSubmenu;
