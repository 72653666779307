import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState, useContext } from "react";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import LoginUserDataContext from "../../store/login";

const FTPForm = (props) => {
  const [userData, setUserData] = useContext(LoginUserDataContext)
  const emailList = props?.values?.ftpEmailList;
  const blobemailList = props?.values?.blobEmailList;
  const [showFtp, setShowFtp] = useState(false);
  const [showBlob, setShowBlob] = useState(false);
  const [showPW, setShowPW] = useState(false);
  const [showSecret, setShowSecret] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const errorPosition = "center";
  const toast = useRef(null);
  const handleTestConnection = () => {
    props?.testConnection();
  };
  const changeTab = (tabIndex) => {
    props?.tabChange(tabIndex);
  };

  const sourceValues = [
    { label: "-- Select --", id: 1 },
    { label: "FTP", id: 2 },
    { label: "External BLOB", id: 3 }
  ];
  console.log(userData)

  useEffect(() => {
    if (props?.values?.source?.id == 3) {
      setSelectedOption({ label: "External BLOB", id: 3 });
      setShowBlob(true);
      setShowFtp(false);
    } else if (props?.values?.source?.id == 2) {
      setSelectedOption({ label: "FTP", id: 2 });
      setShowBlob(false);
      setShowFtp(true);
    } else {
      props?.setFieldValue("source", { label: "-- Select --", id: 1 });
      setSelectedOption({ label: "-- Select --", id: 1 });
    }
  }, [props?.setFieldValue]);

  const passwordChange = (event) => {
    const { value } = event.target;
    props?.setFieldValue("password", value);
  };

// Helper function to check if a value is neither null, undefined, empty string, nor false (for booleans).
const isValidValue = (value) => {
  return value !== null && value !== undefined && value !== '' && value !== false;
};

// Helper function to check all necessary fields from props.values
const hasValidFields = (values) => {
  const fieldsToCheck = [
    'storageAccName', 'storageContName', 'pathToFiles', 'blobArchPath', 'fileNamePrefix', 
    'blobarchiveFilesCheck', 'blobEmailList', 'disableblob', 'tenantid', 'clientid', 
    'clientSecret', 'site', 'ftpEmailList', 'userId', 'password', 'sourceDir', 
    'ediArchDir', 'archiveFilesCheck', 'fileStartsWith', 'disableftp'
  ];
  
  // Check if any field is valid
  return fieldsToCheck.some((field) => isValidValue(values[field]?.trim ? values[field].trim() : values[field]));
};

const handleSourceChange = (event) => {
  const { values } = props;
  const { value } = event.target;

  // Simplified condition using the helper function
  if (hasValidFields(values)) {
    setShowDialog(true);
    setSelectedOption(value);
    return;
  }

  // Set field value and handle options display based on value.id
  props.setFieldValue("source", value);

  if (value.id === 3) {
    setShowFtp(false);
    setShowBlob(true);
  } else if (value.id === 2) {
    setShowFtp(true);
    setShowBlob(false);
  } else {
    setShowFtp(false);
    setShowBlob(false);
  }
  setShowDialog(false);
  setSelectedOption(value);
};


  const togglepassword = (toggle) => {
    if (!props?.isEdited && !props?.isReadonly ) {
      setShowPW(toggle);
    }
    else if(props?.isEdited &&  userData?.roleId == 1){
      setShowPW(toggle);
    }
    else if(!props?.isReadonly){
      setShowPW(toggle);
    }
  };

  const toggleSecrect = (toggle) => {
    if (!props?.isEdited && !props?.isReadonly ) {
      setShowSecret(toggle);
    }
    else if(props?.isEdited &&  userData?.roleId == 1){
      setShowSecret(toggle);
    }
    else if(!props?.isReadonly){
      setShowSecret(toggle);
    }
  };

  const updateAutomatedSettings = () => {
    setShowDialog(false);
    props?.setFieldValue("source", selectedOption);
    if (selectedOption.id == 3) {
      props?.resetFTP();
      setShowFtp(false);
      setShowBlob(true);
    } else if (selectedOption.id == 2) {
      props?.resetBlob();
      setShowFtp(true);
      setShowBlob(false);
    } else {
      props?.resetBlob();
      props?.resetFTP();
      setShowFtp(false);
      setShowBlob(false);
    }
  };

  const footerContent = (
    <div style={{ float: "right" }}>
      <Button
        label="No"
        type="button"
        size="large"
        onClick={() => setShowDialog(false)}
        className={` ${props?.styles.tirnaryBtn}`}
        autoFocus
      />
      <Button
        label="Yes"
        type="submit"
        size="large"
        onClick={updateAutomatedSettings}
        className={props?.styles.primaryBtn}
      />
    </div>
  );

  return (
    <div className="grid">
      <div className="col-12 md:col-3">
        <div className={props?.styles.formLabelContainer}>
          <label htmlFor="source">
            <span className={props?.styles.formLabel}>Source</span>
            <span className="star">*</span>
          </label>
        </div>
        <Dropdown
          id="source"
          disabled={props?.isReadonly}
          placeholder="Select Source"
          value={props?.values?.source}
          options={sourceValues}
          optionLabel="label"
          onChange={(e) => handleSourceChange(e)}
          className={`${props?.styles.dropdown} ${props?.styles.formControl}`}
        />
        <div>
          {props?.errors.source && props?.touched.source && (
            <span className={props?.styles?.errorMsg}>
              {props?.errors.source}
            </span>
          )}
        </div>
      </div>
      {/* <div className="col-12">
        {(showBlob || showFtp) && (
          <hr style={{ borderBottom: "1px solid #CED4DA" }} />
        )}
      </div> */}

      {showFtp && (
        <>
          <div className="col-12">
            <h5 className={props?.styles.blob_heading}>FTP Details</h5>
          </div>
          <div className="col-12">
            <div className="grid">
              <div className="col-12 md:col-6">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="site">
                    <span className={props?.styles.formLabel}>Site</span>
                  </label>
                </div>
                <InputText
                  id="site"
                  disabled={props?.isReadonly}
                  name="site"
                  value={props?.values?.site}
                  onChange={props?.changeEvent}
                  className={`${props?.styles.inputField} ${props?.styles.formControl}`}
                />
                <div>
                  {props?.errors.site && props?.touched.site && (
                    <span className={props?.styles?.errorMsg}>
                      {props?.errors.site}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="region">
                    <span className={props?.styles.formLabel}>
                      Email List (,)
                    </span>
                  </label>
                  <Tooltip target=".email-list" />

                  <i
                    className="email-list pi pi-info-circle p-text-secondary "
                    data-pr-tooltip="Adding your email will allow MOJO to notify you about any issues or failures. This field is optional"
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{
                      fontSize: "15px",
                      cursor: "pointer",
                      padding: "0px 5px"
                    }}
                  ></i>
                </div>
                <InputTextarea
                  value={emailList}
                  id="ftpEmailList"
                  name="ftpEmailList"
                  rows={5}
                  cols={30}
                  disabled={props?.isReadonly}
                  onChange={props?.changeEvent}
                  style={{ resize: "none" }}
                  className={`${props?.styles.formControl}`}
                />
                <div>
                  {props?.errors.ftpEmailList &&
                    props?.touched.ftpEmailList && (
                      <span className={props?.styles?.errorMsg}>
                        {props?.errors.ftpEmailList}
                      </span>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className={props?.styles.formLabelContainer}>
              <label htmlFor="userId">
                <span className={props?.styles.formLabel}>User ID</span>
              </label>
            </div>
            <InputText
              id="userId"
              disabled={props?.isReadonly}
              name="userId"
              value={props?.values?.userId}
              onChange={props?.changeEvent}
              className={`${props?.styles.inputField} ${props?.styles.formControl}`}
            />
            <div>
              {props?.errors.userId && props?.touched.userId && (
                <span className={props?.styles?.errorMsg}>
                  {props?.errors.userId}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className={props?.styles.formLabelContainer}>
              <label htmlFor="password">
                <span className={props?.styles.formLabel}>Password</span>
              </label>
            </div>
            {/* <Password   id="password"   disabled={props?.isReadonly} feedback={false} name="password" value={props?.values?.password} onChange={(e) => passwordChange(e)} toggleMask  className={`${props?.styles.inputField} ${props?.styles.formControl}`} /> */}
            <div className="p-inputgroup flex-1">
              <InputText
                id="password"
                disabled={props?.isReadonly}
                name="password"
                type={showPW ? "text" : "password"}
                value={props?.values?.password}
                onChange={(e) => passwordChange(e)}
                className={`${props?.styles.inputField} ${props?.styles.formControl}`}
              />
              {showPW ? (
                <span className="p-inputgroup-addon">
                  <i
                    className="pi pi-eye"
                    style={{ cursor: "pointer" }}
                    onClick={() => togglepassword(false)}
                  ></i>
                </span>
              ) : (
                <span className="p-inputgroup-addon">
                  <i
                    className="pi pi-eye-slash"
                    style={{ cursor: "pointer" }}
                    onClick={() => togglepassword(true)}
                  ></i>
                </span>
              )}
            </div>
            <div>
              {props?.errors.password && props?.touched.password && (
                <span className={props?.styles?.errorMsg}>
                  {props?.errors.password}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className={props?.styles.formLabelContainer}>
              <label htmlFor="sourceDir">
                <span className={props?.styles.formLabel}>
                  Source Directory
                </span>
              </label>
            </div>
            <InputText
              id="sourceDir"
              disabled={props?.isReadonly}
              name="sourceDir"
              value={props?.values?.sourceDir}
              onChange={props?.changeEvent}
              className={`${props?.styles.inputField} ${props?.styles.formControl}`}
            />
            <div>
              {props?.errors.sourceDir && props?.touched.sourceDir && (
                <span className={props?.styles?.errorMsg}>
                  {props?.errors.sourceDir}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className={props?.styles.formLabelContainer}>
              <label htmlFor="ediArchDir">
                <span className={props?.styles.formLabel}>
                  FTP Archive Directory
                </span>
              </label>
              <Tooltip target=".FTP-arch-file" />

              <i
                className="FTP-arch-file pi pi-info-circle p-text-secondary "
                data-pr-tooltip="This directory belongs to FTP site"
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center-2"
                style={{
                  fontSize: "15px",
                  cursor: "pointer",
                  padding: "0px 5px"
                }}
              ></i>
            </div>
            <InputText
              id="ediArchDir"
              disabled={props?.isReadonly}
              name="ediArchDir"
              value={props?.values?.ediArchDir}
              onChange={props?.changeEvent}
              className={`${props?.styles.inputField} ${props?.styles.formControl}`}
            />
            <div>
              {props?.errors.ediArchDir && props?.touched.ediArchDir && (
                <span className={props?.styles?.errorMsg}>
                  {props?.errors.ediArchDir}
                </span>
              )}
            </div>
            <div className="p-2">
              <Checkbox
                inputId="archiveFilesCheck"
                name="archiveFilesCheck"
                checked={props?.values.archiveFilesCheck}
                onChange={props?.changeEvent}
                onBlur={props?.blurEvent}
                disabled={props?.isReadonly}
              />
              <label htmlFor="archiveFilesCheck" className="ml-2">
                Archive Files
              </label>
              <Tooltip target=".blob-arch-file" />

              <i
                className="blob-arch-file pi pi-info-circle p-text-secondary "
                data-pr-tooltip="Enabling the archive feature will save files to the specified path"
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center-2"
                style={{
                  fontSize: "15px",
                  cursor: "pointer",
                  padding: "0px 5px"
                }}
              ></i>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className={props?.styles.formLabelContainer}>
              <label htmlFor="fileStartsWith">
                <span className={props?.styles.formLabel}>
                  File Name Prefixes (;)
                </span>
              </label>
            </div>
            <InputText
              id="fileStartsWith"
              disabled={props?.isReadonly}
              name="fileStartsWith"
              value={props?.values?.fileStartsWith}
              onChange={props?.changeEvent}
              className={`${props?.styles.inputField} ${props?.styles.formControl}`}
            />
            <div>
              {props?.errors.fileStartsWith &&
                props?.touched.fileStartsWith && (
                  <span className={props?.styles?.errorMsg}>
                    {props?.errors.fileStartsWith}
                  </span>
                )}
            </div>
          </div>
          <div
            className="col-12 md:col-6"
            style={{ display: "flex", flexDirection: "column-reverse" }}
          >
            <div className={props?.styles?.checkboxFields}>
              <div className="p-2">
                <Checkbox
                  inputId="disableftp"
                  name="disableftp"
                  checked={props?.values.disableftp}
                  onChange={props?.changeEvent}
                  onBlur={props?.blurEvent}
                  disabled={props?.isReadonly}
                />
                <label htmlFor="disableftp" className="ml-2">
                  Disable FTP function
                </label>

                <Tooltip target=".custom-target-icon" />

                <i
                  className="custom-target-icon pi pi-info-circle p-text-secondary "
                  data-pr-tooltip="Enabling the check mark will deactivate automation for this function"
                  data-pr-position="right"
                  data-pr-at="right+5 top"
                  data-pr-my="left center-2"
                  style={{
                    fontSize: "15px",
                    cursor: "pointer",
                    padding: "0px 5px"
                  }}
                ></i>
              </div>
            </div>
          </div>
        </>
      )}

      {showBlob && (
        <>
          <div className="col-12">
            <h5 className={props?.styles.blob_heading}>Blob Details</h5>
          </div>
          <div className="col-12">
            <div className="grid">
              <div className="col-12 md:col-6">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="storageAccName">
                    <span className={props?.styles.formLabel}>
                      Storage Account Name
                    </span>
                  </label>
                </div>
                <InputText
                  id="storageAccName"
                  disabled={props?.isReadonly}
                  name="storageAccName"
                  value={props?.values?.storageAccName}
                  onChange={props?.changeEvent}
                  className={`${props?.styles.inputField} ${props?.styles.formControl}`}
                />
                <div>
                  {props?.errors.storageAccName &&
                    props?.touched.storageAccName && (
                      <span className={props?.styles?.errorMsg}>
                        {props?.errors.storageAccName}
                      </span>
                    )}
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="storageContName">
                    <span className={props?.styles.formLabel}>
                      Storage Container Name
                    </span>
                  </label>
                </div>
                <InputText
                  id="storageContName"
                  disabled={props?.isReadonly}
                  name="storageContName"
                  value={props?.values?.storageContName}
                  onChange={props?.changeEvent}
                  className={`${props?.styles.inputField} ${props?.styles.formControl}`}
                />
                <div>
                  {props?.errors.storageContName &&
                    props?.touched.storageContName && (
                      <span className={props?.styles?.errorMsg}>
                        {props?.errors.storageContName}
                      </span>
                    )}
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="pathToFiles">
                    <span className={props?.styles.formLabel}>
                      Path to Files
                    </span>
                  </label>
                </div>
                <InputText
                  id="pathToFiles"
                  disabled={props?.isReadonly}
                  name="pathToFiles"
                  value={props?.values?.pathToFiles}
                  onChange={props?.changeEvent}
                  className={`${props?.styles.inputField} ${props?.styles.formControl}`}
                />
                <div>
                  {props?.errors.pathToFiles && props?.touched.pathToFiles && (
                    <span className={props?.styles?.errorMsg}>
                      {props?.errors.pathToFiles}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="fileNamePrefix">
                    <span className={props?.styles.formLabel}>
                      File Name Prefixes (;)
                    </span>
                  </label>
                </div>
                <InputText
                  id="fileNamePrefix"
                  disabled={props?.isReadonly}
                  name="fileNamePrefix"
                  value={props?.values?.fileNamePrefix}
                  onChange={props?.changeEvent}
                  className={`${props?.styles.inputField} ${props?.styles.formControl}`}
                />
                <div>
                  {props?.errors.fileNamePrefix &&
                    props?.touched.fileNamePrefix && (
                      <span className={props?.styles?.errorMsg}>
                        {props?.errors.fileNamePrefix}
                      </span>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="grid">
              <div className="col-12 md:col-6">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="archiveContainerName">
                    <span className={props?.styles.formLabel}>
                      Archive Container Name
                    </span>
                  </label>
                </div>
                <InputText
                  id="archiveContainerName"
                  disabled={props?.isReadonly}
                  name="archiveContainerName"
                  value={props?.values?.archiveContainerName}
                  onChange={props?.changeEvent}
                  className={`${props?.styles.inputField} ${props?.styles.formControl}`}
                />
                <div>
                  {props?.errors.archiveContainerName &&
                    props?.touched.archiveContainerName && (
                      <span className={props?.styles?.errorMsg}>
                        {props?.errors.archiveContainerName}
                      </span>
                    )}
                </div>
                <div className="p-2">
                  <Checkbox
                    inputId="blobarchiveFilesCheck"
                    name="blobarchiveFilesCheck"
                    checked={props?.values.blobarchiveFilesCheck}
                    onChange={props?.changeEvent}
                    onBlur={props?.blurEvent}
                    disabled={props?.isReadonly}
                  />
                  <label htmlFor="blobarchiveFilesCheck" className="ml-2">
                    Archive Files
                  </label>
                  <Tooltip target=".blob-arch-file" />

                  <i
                    className="blob-arch-file pi pi-info-circle p-text-secondary "
                    data-pr-tooltip="Enabling the archive feature will save files to the specified path"
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{
                      fontSize: "15px",
                      cursor: "pointer",
                      padding: "0px 5px"
                    }}
                  ></i>
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="blobArchPath">
                    <span className={props?.styles.formLabel}>
                      Path to Archive Files
                    </span>
                  </label>
                </div>
                <InputText
                  id="blobArchPath"
                  disabled={props?.isReadonly}
                  name="blobArchPath"
                  value={props?.values?.blobArchPath}
                  onChange={props?.changeEvent}
                  className={`${props?.styles.inputField} ${props?.styles.formControl}`}
                />
                <div>
                  {props?.errors.blobArchPath &&
                    props?.touched.blobArchPath && (
                      <span className={props?.styles?.errorMsg}>
                        {props?.errors.blobArchPath}
                      </span>
                    )}
                </div>
              </div>
              <div className="col-12">
                <div className="grid">
                  <div className="col-12 md:col-6">
                    <div className={props?.styles.formLabelContainer}>
                      <label htmlFor="blobEmailList">
                        <span className={props?.styles.formLabel}>
                          Email List (,)
                        </span>
                      </label>
                      <Tooltip target=".email-list" />

                      <i
                        className="email-list pi pi-info-circle p-text-secondary "
                        data-pr-tooltip="Adding your email will allow MOJO to notify you about any issues or failures. This field is optional"
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center-2"
                        style={{
                          fontSize: "15px",
                          cursor: "pointer",
                          padding: "0px 5px"
                        }}
                      ></i>
                    </div>
                    <InputTextarea
                      value={blobemailList}
                      id="blobEmailList"
                      name="blobEmailList"
                      rows={5}
                      cols={30}
                      disabled={props?.isReadonly}
                      onChange={props?.changeEvent}
                      style={{ resize: "none" }}
                      className={`${props?.styles.formControl}`}
                    />
                    <div>
                      {props?.errors.blobEmailList &&
                        props?.touched.blobEmailList && (
                          <span className={props?.styles?.errorMsg}>
                            {props?.errors.blobEmailList}
                          </span>
                        )}
                    </div>
                  </div>
                  <div
                    className="col-12 md:col-6"
                    style={{ display: "flex", flexDirection: "column-reverse" }}
                  >
                    <div
                      className={`props?.styles?.checkboxFields ${props?.styles?.disableblob_Field}`}

                    >
                      <div className="p-2">
                        <Checkbox
                          inputId="disableblob"
                          name="disableblob"
                          checked={props?.values.disableblob}
                          onChange={props?.changeEvent}
                          onBlur={props?.blurEvent}
                          disabled={props?.isReadonly}
                        />
                        <label htmlFor="disableblob" className="ml-2">
                          Disable BLOB function
                        </label>

                        <Tooltip target=".blob-disable" />

                        <i
                          className="blob-disable pi pi-info-circle p-text-secondary "
                          data-pr-tooltip="Enabling the check mark will deactivate automation for this function"
                          data-pr-position="right"
                          data-pr-at="right+5 top"
                          data-pr-my="left center-2"
                          style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            padding: "0px 5px"
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <h5 className={props?.styles.blob_heading}>Authentication</h5>
          </div>
          <div className="col-12">
            <div className="grid">
              <div className="col-12 md:col-6">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="clientid">
                    <span className={props?.styles.formLabel}>Client ID</span>
                  </label>
                </div>
                <InputText
                  id="clientid"
                  disabled={props?.isReadonly}
                  name="clientid"
                  value={props?.values?.clientid}
                  onChange={props?.changeEvent}
                  className={`${props?.styles.inputField} ${props?.styles.formControl}`}
                />
                <div>
                  {props?.errors.clientid && props?.touched.clientid && (
                    <span className={props?.styles?.errorMsg}>
                      {props?.errors.clientid}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="clientSecret">
                    <span className={props?.styles.formLabel}>
                      Client Secret
                    </span>
                  </label>
                </div>
                <div className="p-inputgroup flex-1">
                  <InputText
                    id="clientSecret"
                    disabled={props?.isReadonly}
                    name="clientSecret"
                    value={props?.values?.clientSecret}
                    type={showSecret ? "text" : "password"}
                    onChange={props?.changeEvent}
                    className={`${props?.styles.inputField} ${props?.styles.formControl}`}
                  />
                  {showSecret ? (
                    <span className="p-inputgroup-addon">
                      <i
                        className="pi pi-eye"
                        style={{ cursor: "pointer" }}
                        onClick={() => toggleSecrect(false)}
                      ></i>
                    </span>
                  ) : (
                    <span className="p-inputgroup-addon">
                      <i
                        className="pi pi-eye-slash"
                        style={{ cursor: "pointer" }}
                        onClick={() => toggleSecrect(true)}
                      ></i>
                    </span>
                  )}
                </div>

                <div>
                  {props?.errors.clientSecret &&
                    props?.touched.clientSecret && (
                      <span className={props?.styles?.errorMsg}>
                        {props?.errors.clientSecret}
                      </span>
                    )}
                </div>
              </div>
              <div className="col-12 md:col-6">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="tenantid">
                    <span className={props?.styles.formLabel}>Tenant ID</span>
                  </label>
                </div>
                <InputText
                  id="tenantid"
                  disabled={props?.isReadonly}
                  name="tenantid"
                  value={props?.values?.tenantid}
                  onChange={props?.changeEvent}
                  className={`${props?.styles.inputField} ${props?.styles.formControl}`}
                />
                <div>
                  {props?.errors.tenantid && props?.touched.tenantid && (
                    <span className={props?.styles?.errorMsg}>
                      {props?.errors.tenantid}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {!props?.isReadonly && (showBlob || showFtp) && (
        <div className="col-12 md:col-6">
          <Button
            label="Test Connection"
            type="button"
            size="large"
            outlined
            className={props?.styles.thirdBtn}
            onClick={handleTestConnection}
          />
        </div>
      )}
      {/* <div className="col-12">
        {(showBlob || showFtp) && (
          <hr style={{ borderBottom: "1px solid #CED4DA" }} />
        )}
      </div> */}
      <div className="col-12 text-right">
        <Button
          label="Previous"
          type="button"
          size="large"
          icon="pi pi-angle-left"
          iconPos="left"
          outlined
          onClick={() => changeTab(2)}
          className={props?.styles.secondaryBtn}
        />
      </div>
      <Toast ref={toast} position="center" />
      {showDialog && (
        <Dialog
          header="Alert"
          visible={showDialog}
          style={{ width: "50vw" }}
          position={errorPosition}
          footer={footerContent}
          onHide={() => setShowDialog(false)}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <i
              className="pi pi-exclamation-triangle px-2"
              style={{ fontSize: "2rem", color: "#F97316" }}
            />
            <p>
              Automated Settings data will be lost. Are you sure you want to
              continue?
            </p>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default FTPForm;
