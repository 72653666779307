import React, { useEffect, useState, useRef, memo } from "react";
import sanitizeHtml from "sanitize-html";
import ContentEditable from "react-contenteditable";
import "./autocompletediv.css";

const initialState = {
  activeOption: 0,
  filteredOptions: [],
  userInput: [],
};

const Autocompetediv = (props) => {
  const [content, setContent] = useState("");
  const inputRef = useRef(null);
  const optionRefs = useRef([]);
  const [componentinfo, setComponentinfo] = useState(initialState);
  const { activeOption, filteredOptions, userInput } = componentinfo;
  const [showOptions, setShowOptions] = useState(false);
  const stateRef = useRef();
  stateRef.current = userInput;

  useEffect(() => {
    const handleClickOutside = (e) => {
      let queryResult = document.querySelector(`#${props.id}.searchboxwrapper`);
      let innerText = document.querySelector(
        `#${props.id} .search-box`
      )?.innerText;
      if (innerText && queryResult && !queryResult.contains(e.target)) {
        setShowOptions(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    setComponentinfo((prevState) => ({
      ...prevState,
      userInput: props.values,
    }));
    showContent(props.values, "");

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setComponentinfo((prevState) => ({
      ...prevState,
      userInput: props.values,
    }));
  }, [props.values]);

  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  const onClick = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
    let userInputList = userInput;
    userInputList.push(e.currentTarget.innerText);

    setComponentinfo((prevState) => ({
      ...prevState,
      activeOption: 0,
      filteredOptions: [],
      userInput: userInputList,
    }));
    setShowOptions(false);
    props.onBlur(userInputList);
    showContent(userInputList);
    inputRef.current.focus();
  };

  const showContent = (userInputList = [], evttext) => {
    const sanitizeConf = {
      allowedTags: ["span"],
      allowedAttributes: { span: ["contenteditable"] },
    };
    let html = evttext ? evttext : "";
    const content = userInputList
      .map((item) => {
        return `<span contenteditable="false">${item}, </span>`;
      })
      .join("");
    html = content + html;
    setContent(sanitizeHtml(html, sanitizeConf));
  };

  const onContentChange = (evt) => {
    const { options } = props;
    let userInputList = [];
    let innerText = evt.currentTarget.innerText;
    let userInputText = "";
    if (innerText.search(",") > -1) {
      userInputList = innerText.split(",");
      userInputText = userInputList[userInputList.length - 1];
      userInputList.pop();
    } else {
      userInputText = evt.currentTarget.innerText;
    }
    userInputText = userInputText.trim();
    if (userInputText !== "") {
      const filteredOptions = options.filter((optionName) => {
        userInputList = userInputList.map((ele) => ele.trim());
        return (
          !userInputList.includes(optionName) &&
          optionName.toLowerCase().indexOf(userInputText.toLowerCase()) > -1
        );
      });
      setComponentinfo((prevState) => ({
        ...prevState,
        activeOption: 0,
        filteredOptions,
        userInput: userInputList,
      }));
      setShowOptions(true);
      props.onBlur(userInputList);
    }
    if (stateRef.current.length !== userInputList.length) {
      props.onBlur(userInputList);
    }
    showContent(userInputList, userInputText);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
    if (e.keyCode === 8) {
      setShowOptions(false);
    }
    if (showOptions && filteredOptions.length) {
      if (e.keyCode === 13) {
        let userInputList = userInput;
        userInputList.push(filteredOptions[activeOption]);
        setComponentinfo((prevState) => ({
          ...prevState,
          activeOption: 0,
          userInput: userInputList,
        }));
        setShowOptions(false);
        props.onBlur(userInputList);
        showContent(userInputList);
      } else if (e.keyCode === 38) {
        if (activeOption === 0) {
          return;
        }
        setComponentinfo((prevState) => ({
          ...prevState,
          activeOption: activeOption - 1,
        }));
      } else if (e.keyCode === 40) {
        if (activeOption === filteredOptions.length - 1) {
          return;
        }
        setComponentinfo((prevState) => ({
          ...prevState,
          activeOption: activeOption + 1,
        }));
      }
    }
  };

  useEffect(() => {
    if (showOptions && optionRefs.current[activeOption]) {
      optionRefs.current[activeOption].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [activeOption]);

  const displayOptionList = () => {
    let optionList;
    if (showOptions) {
      if (filteredOptions.length) {
        optionRefs.current = [];
        optionList = (
          <div className="options_Container">
            <ul className="options">
              {filteredOptions.map((optionName, index) => {
                let className;
                if (index === activeOption) {
                  className = "option-active";
                }
                return (
                  <li
                    ref={(el) => (optionRefs.current[index] = el)}
                    className={className}
                    key={optionName}
                    onClick={onClick}
                  >
                    {optionName}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      }
    }
    return optionList;
  };

  return (
    <React.Fragment>
      <div id={props.id} className="searchboxwrapper">
        <div className="search">
          <div className="search-box" onKeyDown={onKeyDown}>
            <ContentEditable
              disabled={props.disabled}
              innerRef={inputRef}
              onChange={onContentChange}
              html={content}
              style={{
                border: "none",
                width: "100%",
                height: "100%",
                overflow: "auto",
                scrollbarWidth: "thin",
                outlineColor: "transparent",
              }}
              className={`${props?.disabled ? "disabledFields" : ""}`}
            />
          </div>
        </div>
        {displayOptionList()}
      </div>
    </React.Fragment>
  );
};

export default memo(Autocompetediv);
