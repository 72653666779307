import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { ProgressBar } from 'primereact/progressbar';
import { IoCubeOutline } from "react-icons/io5";

        

export const FunctionHeader = (props) => {
  return (
    <div className={`grid ${props.styles.headerContainer}`}>
      <div className="col-12 md:col-6">
        <span className={props.styles.formLabel}>Region</span>
        <Dropdown
          value={props.filteredValue}
          onChange={props.onFilter}
          options={props.filterOptionsList}
          optionLabel="regionName"
          filter={true}
          className={props.styles.filterDropdown}
        ></Dropdown>
      </div>
      <div
        className={`col-12 ${props.isCopyEnable ? "md:col-4" : "md:col-4 md:col-offset-2"} ${
          props.styles.searchBar
        }`}
      >
        {/* <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={props.searchedItem}
            onChange={props.onSearch}
            placeholder="Search"
          />
        </span> */}
        <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                    <i className="pi pi-search"></i>
                </span>
                <InputText
            value={props.searchedItem}
            onChange={props.onSearch}
            placeholder="Search"
            className={`${props?.styles.noBorder}`}
          />
            </div>
      </div>
      <>
        {props.isCopyEnable && (
          <div className="col-12 md:col-2 ">
            <Button
              label="Copy"
              disabled={!props?.data.length}
              type="button"
              onClick={props?.copyTable}
              className={`${props?.styles.primaryBtn} ${props?.styles.filterControls}`}
            />
          </div>
        )}
      </>
    </div>
  );
};

export const CreateOrderTableHeader = (props) => {
  return (
    <div className="grid">
      <div className="col-12 md:col-7">
        <div className="grid">
          <div className={`col-12 md:col-5`}>
            <Dropdown
              value={props?.selectedStatus}
              onChange={props?.onStatusSelect}
              options={props?.statusList}
              optionLabel="label"
              placeholder="Select Status"
              className={`${props?.styles.dropdown} ${props?.styles.filterControls}`}
            />
          </div>
          <div className={`col-12 md:col-5`}>
<div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                    <i className="pi pi-search"></i>
                </span>
                <InputText
                value={props?.searchCustId}
                onChange={props?.onSearch}
                placeholder="Cust ID"
                className={`${props?.styles.filterControls} ${props?.styles.noBorder}`}
              />
            </div>
          </div>
          {/* <div className={`col-12 md:col-5`}>
            <span
              className={`p-input-icon-left ${props?.styles.filterControls}`}
            >
              <i className="pi pi-search" />
              <InputText
                value={props?.searchCustId}
                onChange={props?.onSearch}
                placeholder="Cust ID"
                className={props?.styles.filterControls}
              />
            </span>
          </div> */}
          <div className="col-12 md:col-2">
            <Button
              label="Clear"
              type="button"
              size="Normal"
              onClick={props?.onClear}
              className={`${props?.styles.primaryBtn} ${props?.styles.filterControls}`}
            />
          </div>
        </div>
      </div>
      <>
        {props.isCopyEnable && (
          <div className="col-12 md:col-2 md:col-offset-3">
            {/* <Button
              label="Copy"
              disabled={!props?.data.length}
              type="button"
              onClick={props?.copyTable}
              className={`${props?.styles.primaryBtn} ${props?.styles.filterControls}`}
            /> */}
             {/* <Button type="button" disabled={!props?.data.length} icon="pi pi-file-excel" severity="success" rounded onClick={props?.exportExcel} data-pr-tooltip="XLS"  /> */}
             <i disabled={!props?.data.length}
                className={`${props?.styles.downloadIcon} pi pi-download ${props?.data.length ? props?.styles.enableDownloadIcon : '' }`}
                onClick={props?.exportExcel}
                  ></i>
          </div>
        )}
      </>
    </div>
  );
};

export const OrderSummaryTableHeader = (props) => {
  return (
    <div className={` grid ${props?.styles?.orderSummaryContainer}`}>
      <div className={`col-6`}>
        <div className={`grid ${props?.styles?.card}`}>
          <div className="col-12">
            <div className="grid" style={{alignItems:'center', justifyContent:'center'}}>
            <div className="col-12 md:col-2 ">
            <i className={`pi pi-shopping-cart ${props?.styles.cartIcon}`}> </i>
          </div>
          <div className="col-12 md:col-7"><span className={props?.styles.cardTitle}>Total Stores Ordered: {props?.orderSummaryCradData?.currentStoreCount}</span></div>
          <div className="col-12 md:col-3"><span className={props?.styles.cardPercentage}>{props?.orderSummaryCradData?.storeTolerance}%</span></div>
            </div>
          </div>
          <div className="col-12"><ProgressBar value={props?.orderSummaryCradData?.storeTolerance} style={{height:'6px'}} className={props?.orderSummaryCradData?.storeToleranceColor === 1?`${props?.styles?.green}`:props?.orderSummaryCradData?.storeToleranceColor === 2 ? `${props?.styles?.blue}`:`${props?.styles?.red}`}></ProgressBar></div>
        </div>
      </div>
      <div className={`col-6`}>
        <div className={`grid ${props?.styles?.card}`}>
          <div className="col-12">
          <div className="grid" style={{alignItems:'center', justifyContent:'center'}}>
          <div className="col-2 md:col-2 ">
          <i className={`pi pi-database ${props?.styles.dbIcon}`}> </i>
          </div>
          <div className="col-12 md:col-7"><span className={props?.styles.cardTitle}>Total Quantity Ordered: {props?.orderSummaryCradData?.totalItemCount}</span></div>
          <div className="col-12 md:col-3"><span className={props?.styles.cardPercentage}>{props?.orderSummaryCradData?.totalItemsTolerance}%</span></div>
          </div>
          </div>
          <div className="col-12"><ProgressBar value={props?.orderSummaryCradData?.totalItemsTolerance} style={{height:'6px'}} className={props?.orderSummaryCradData?.itemToleranceColor === 1?`${props?.styles?.green}`:props?.orderSummaryCradData?.itemToleranceColor === 2 ? `${props?.styles?.blue}`:`${props?.styles?.red}`}></ProgressBar></div>
        </div>
      </div>
    </div>
  );
};


export const logSummaryHeader = (props) =>{
  return(
  <div className={`grid`}>
       <div className={`col-6`}>
       <Dropdown
          value={props.filteredStatus}
          onChange={props.onStatusFilter}
          options={props.statusFilterOptions}
          placeholder="Select Status"
          optionLabel="label"
          filter={true}
        ></Dropdown>
          <Button
              label="Clear"
              type="button"
              onClick={props?.ClearStatusFilter}
              className={`${props?.styles.primaryBtn} ${props?.styles.filterControls} mx-2`}
            />
       </div>
  </div>
  )
}