import { api } from "../api/api";

export const getEdiPurchaseOrders = async (params) => {
  try {
    const response = await api.get("/api/EdiPurchaseOrder", { params });
    return response.data?.result?.result || [];
  } catch (error) {
    throw new Error(error?.response?.data?.title);
  }
};

export const updateEdiPurchaseOrder = async (payload) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const response = await api.put(`/api/EdiPurchaseOrder`, payload, config);
    return response.data?.result?.result || [];
  } catch (error) {
    throw new Error(error?.response?.data?.title);
  }
};

// export const reProcessEdiPurchaseOrder = async (payload) => {
//   try {
//     const config = {
//       headers: {
//         "Content-Type": "application/json"
//       }
//     };
//     const response = await api.put(
//       `/api/EdiPurchaseOrder/ReProcessEdiPO`,
//       payload,
//       config
//     );
//     return response.data?.result?.result || [];
//   } catch (error) {
//     throw new Error(error?.response?.data?.title);
//   }
// };

export const getEdiFileContent = async (params) => {
  try {
    const response = await api.get("/api/EdiPurchaseOrder/GetEdiFileContent", {
      params
    });
    return response.data?.result?.result || [];
  } catch (error) {
    throw new Error(error?.response?.data?.title);
  }
};
