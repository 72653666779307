import React from "react";
import Table from "../../components/Table/Table";
import * as constants from "../../constants/constants";
import { orderSummaryColumns } from "../../constants/table-columns/tableColumns";
import { orderSummaryFooter } from "../../components/Table/table-footer/tablefooter";
import { OrderSummaryTableHeader } from "../../components/Table/table-header/tableHeader"
import styles from "./orderSummary.module.scss";
import generateXML from "../../services/generateXML";
import { appInsights } from '../../AppInsights';

const OrderSummary = (props) => {
    const successRecords = props?.filteredSuccessRecords;
    const orderSummaryCradData = props?.orderSummaryData;
    const orderSummaryData = props?.orderSummaryData?.itemListResponseDtos;
    const processedData = props?.processedData;
    const ongenerateXml = async() => {
        try{
            props?.setShowOrderSummarySpinner(true)
            const result = await generateXML(processedData, false);
            props?.setShowXMLList(true);
            props?.setShowXMLListError(false)
            props?.setShowOrderSummary(false);
            props?.setXmlListErrorText("")
            props?.setXMLFileList(result)
            props?.setShowOrderSummarySpinner(false)
        }
        catch(error){
            props?.setShowOrderSummarySpinner(false)
            props?.setShowXMLListError(true);
            props?.setXmlListErrorText(error?.message);
            props?.setShowXMLList(false);
            appInsights.trackException({ exception: error });
        }
    }

    const closeOrderSummary = () =>{
        props?.setShowOrderSummary(false);
    }
    return (
       <>
            <Table
            content={orderSummaryData} 
            columnsData={orderSummaryColumns}
            rows={constants.defaultRows}
            rowsPerPageOptions={constants.rowsPerPageOptions}
            paginator={constants.paginator}
            scrollable={constants.scrollable}
            emptyMessage={constants.emptyMessage}
            tableStyle={constants.tableStyle}
            currentPageReportTemplate={constants.currentPageReportTemplate}
            paginatorTemplate={constants.paginatorTemplate}
            header={() => OrderSummaryTableHeader({styles,orderSummaryCradData })}
            footer={()=>orderSummaryFooter({styles, ongenerateXml, closeOrderSummary})}
            />
       </>
    )
}

export default OrderSummary;