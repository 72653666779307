import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import AppReducers from "./store/index";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let __DEV__;
const loggerMiddleware = createLogger({
  predicate: (getState, action) => __DEV__
});

let AppStore = createStore(
  AppReducers,
  {},
  composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware))
);

export default AppStore;
