import * as actionTypes from "./actions";

const initialState = {
  regions: [],
  walmartRegions: [],
  functions: {},
  allfunctions: [],
  allfunctionsForLogSummary: [],
  masterData: {},
  functionDetailsById: {}
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REGIONS:
      return {
        ...state,
        regions: [...action.value]
      };
    case actionTypes.GET_WALMART_REGIONS:
      return {
        ...state,
        walmartRegions: [...action.value]
      };
    case actionTypes.GET_FUNCTIONS:
      return {
        ...state,
        functions: {
          ...state.functions,
          ...action.value
        }
      };
    case actionTypes.GET_FUNCTIONS_ALL:
      return {
        ...state,
        allfunctions: [...action.value]
      };
    case actionTypes.GET_FUNCTIONS_ALL_For_LogSummary:
      return {
        ...state,
        allfunctionsForLogSummary: [...action.value]
      };
    case actionTypes.GET_FUNCTION_MASTERDATA:
      return {
        ...state,
        masterData: {
          ...action.value
        }
      };
    case actionTypes.GET_FUNCTION_DETAILS_BY_ID:
      return {
        ...state,
        functionDetailsById: {
          ...action.value
        }
      };
    default:
      return state;
  }
};

export default orderReducer;
