import { combineReducers } from "redux";
import orderReducer from "./reducer.order";
const AppReducers = combineReducers({
  orderReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return AppReducers(state, action);
};

export default rootReducer;
