
const downloadLogSummary = async (params) => {
    try {
       if (params.customerId) {
        if(params?.batchId != null){
          if(params?.poNumber !== null){
            window.open(`${process.env.REACT_APP_BASE_URL}/api/LogSummary/downloadexcel?customerId=${params.customerId}&functionId=${params.functionId}&startDate=${params.startDate}&endDate=${params.endDate}&batchId=${params.batchId}&isAutoLogSummary=${params.isAutoLogSummary}&poNumber=${params.poNumber}`, '_blank').focus();
          }
          else{
            window.open(`${process.env.REACT_APP_BASE_URL}/api/LogSummary/downloadexcel?customerId=${params.customerId}&functionId=${params.functionId}&startDate=${params.startDate}&endDate=${params.endDate}&batchId=${params.batchId}&isAutoLogSummary=${params.isAutoLogSummary}`, '_blank').focus();
          }
        }
        else{
          if(params?.poNumber !== null){
            window.open(`${process.env.REACT_APP_BASE_URL}/api/LogSummary/downloadexcel?customerId=${params.customerId}&functionId=${params.functionId}&startDate=${params.startDate}&endDate=${params.endDate}&isAutoLogSummary=${params.isAutoLogSummary}&poNumber=${params.poNumber}`, '_blank').focus();
          }
          else{
            window.open(`${process.env.REACT_APP_BASE_URL}/api/LogSummary/downloadexcel?customerId=${params.customerId}&functionId=${params.functionId}&startDate=${params.startDate}&endDate=${params.endDate}&isAutoLogSummary=${params.isAutoLogSummary}`, '_blank').focus();
          }
        }
       }
       else {
        if(params?.batchId != null){
          if(params?.poNumber !== null){
            window.open(`${process.env.REACT_APP_BASE_URL}/api/LogSummary/downloadexcel?functionId=${params.functionId}&startDate=${params.startDate}&endDate=${params.endDate}&batchId=${params.batchId}&isAutoLogSummary=${params.isAutoLogSummary}&poNumber=${params.poNumber}`, '_blank').focus();
          }
          else{
            window.open(`${process.env.REACT_APP_BASE_URL}/api/LogSummary/downloadexcel?functionId=${params.functionId}&startDate=${params.startDate}&endDate=${params.endDate}&batchId=${params.batchId}&isAutoLogSummary=${params.isAutoLogSummary}`, '_blank').focus();
          }
        }
        else{
          if(params?.poNumber !== null){
            window.open(`${process.env.REACT_APP_BASE_URL}/api/LogSummary/downloadexcel?functionId=${params.functionId}&startDate=${params.startDate}&endDate=${params.endDate}&isAutoLogSummary=${params.isAutoLogSummary}&poNumber=${params.poNumber}`, '_blank').focus();
          }
          else{
            window.open(`${process.env.REACT_APP_BASE_URL}/api/LogSummary/downloadexcel?functionId=${params.functionId}&startDate=${params.startDate}&endDate=${params.endDate}&isAutoLogSummary=${params.isAutoLogSummary}`, '_blank').focus();
          }
        } 
      }
      } catch (error) {
        throw new Error(error?.response?.data?.title);
      }
};

export default downloadLogSummary;