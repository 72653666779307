import { useEffect, useRef, useState } from "react";
import { getEdiPurchaseOrders } from "../../services/ediPurchaseOrder";
import Spinner from "../../components/spinner/spinner";
import { Toast } from "primereact/toast";
import Table from "../../components/Table/ediRowExpansionTable";
import * as constants from "../../constants/constants";
import { ediFilePurchaseOrderColumns } from "../../constants/table-columns/tableColumns";
import { rowExpansionEdiLineItemsColumns } from "../../constants/table-columns/tableColumns";
import styles from "./ediPurchaseOrder.module.scss";
import { Form, Formik } from "formik";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { showStatusOptions } from "../../constants/constants";

const initialValue = {
  purchaseOrderNumber: null,
  vendorSequenceNumber: null,
  showStatus: showStatusOptions.Pending,
  deliveryStartDate: null,
  deliveryEndDate: null
};

const EdiPurchaseOrders = (props) => {
  const toast = useRef(null);
  const formRef = useRef(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const [ediPurchaseOrders, setEdiPurchaseOrders] = useState([]);
  const [disableFields, setDisableFields] = useState(false);
  const [filterValues, setFilterValues] = useState();

  const showStatusList = [
    { label: "All", value: showStatusOptions.All },
    { label: "Pending", value: showStatusOptions.Pending },
    { label: "Submitted", value: showStatusOptions.Submitted },
    { label: "Processed", value: showStatusOptions.Processed },
    { label: "Failed", value: showStatusOptions.Failed }
  ];

  useEffect(() => {
    fetchEdiPurchaseOrders(initialValue);
  }, []);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const fetchEdiPurchaseOrders = async (formbody) => {
    try {
      setShowSpinner(true);

      formbody = {
        purchaseOrderNumber: formbody?.purchaseOrderNumber,
        vendorSequenceNumber: formbody?.vendorSequenceNumber
          ? formbody?.vendorSequenceNumber
          : null,
        showStatus: formbody?.showStatus ? formbody?.showStatus : null,
        deliveryStartDate: formbody?.deliveryStartDate
          ? formatDate(formbody?.deliveryStartDate)
          : null,
        deliveryEndDate: formbody?.deliveryEndDate
          ? formatDate(formbody?.deliveryEndDate)
          : null
      };

      let result = "";
      result = await getEdiPurchaseOrders(formbody);

      setFilterValues(formbody);

      setEdiPurchaseOrders(result);
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      setEdiPurchaseOrders([]);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 4000
      });
    }
  };

  return (
    <>
      {showSpinner && <Spinner />}

      <br></br>

      <Formik
        initialValues={initialValue}
        onSubmit={(values) => {
          setShowSpinner(true);
          fetchEdiPurchaseOrders(values);
        }}
        innerRef={formRef}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit} ref={formRef}>
            <div className="grid">
              <div className="col-12 md:col-2">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="showStatus">
                    <span className={styles.formLabel}>Processing Status</span>
                  </label>
                </div>
                <Dropdown
                  id="showStatus"
                  filter
                  placeholder="Select a Processing Status"
                  value={values?.showStatus}
                  options={showStatusList}
                  onChange={(e) => {
                    setFieldValue("showStatus", e.value);
                  }}
                  className={`${styles.dropdown} ${styles.formControl}`}
                />
              </div>

              <div className="col-12 md:col-2">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="purchaseOrderNumber">
                    <span className={styles.formLabel}>PO Number</span>
                  </label>
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-search"></i>
                  </span>
                  <InputText
                    id="purchaseOrderNumber"
                    placeholder="PO Number"
                    value={values?.purchaseOrderNumber}
                    onChange={handleChange}
                    className={`${styles.inputField} ${styles.formControl} ${styles.noBorder}`}
                  />
                </div>
              </div>

              <div className="col-12 md:col-4">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="vendorSequenceNumber">
                    <span className={styles.formLabel}>
                      Vendor Sequence Number
                    </span>
                  </label>
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-search"></i>
                  </span>
                  <InputText
                    id="vendorSequenceNumber"
                    placeholder="Vendor Sequence Number"
                    value={values?.vendorSequenceNumber}
                    onChange={handleChange}
                    className={`${styles.inputField} ${styles.formControl} ${styles.noBorder}`}
                  />
                </div>
              </div>

              <div className="col-12 md:col-2">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="deliveryStartDate">
                    <span className={styles.formLabel}>
                      Delivery Date Range
                    </span>
                  </label>
                </div>
                <Calendar
                  id="deliveryStartDate"
                  value={values?.deliveryStartDate}
                  onChange={handleChange}
                  disabled={disableFields}
                  placeholder="Start Date"
                  maxDate={formRef?.current?.values?.deliveryEndDate}
                />
              </div>
              <div className="col-12 md:col-2">
                <div className={styles.formLabelContainer}></div>
                <Calendar
                  id="deliveryEndDate"
                  value={values?.deliveryEndDate}
                  onChange={handleChange}
                  disabled={disableFields}
                  placeholder="End Date"
                  minDate={formRef?.current?.values?.deliveryStartDate}
                />
              </div>
            </div>

            <div
              className="flex justify-content-end flex-wrap gap-3"
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              <div className="flex align-items-center justify-content-center">
                <div className={styles.formLabelContainer}></div>
                <Button
                  label="Search"
                  type="submit"
                  size="medium"
                  className={styles.primaryBtn}
                />
              </div>
              <div className="flex align-items-center justify-content-center">
                <div className={styles.formLabelContainer}></div>
                <Button
                  label="Reset"
                  type="button"
                  size="medium"
                  onClick={(e) => {
                    formRef.current.setValues({
                      purchaseOrderNumber: "",
                      vendorSequenceNumber: "",
                      showStatus: showStatusOptions.Pending
                    });

                    fetchEdiPurchaseOrders({
                      purchaseOrderNumber: "",
                      vendorSequenceNumber: "",
                      showStatus: showStatusOptions.Pending
                    });

                    setDisableFields(false);
                  }}
                  className={styles.resetBtn}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <Table
        content={ediPurchaseOrders}
        filterValuesData={filterValues}
        columnsData={ediFilePurchaseOrderColumns}
        rowExpansionColumnsData={rowExpansionEdiLineItemsColumns}
        rows={constants.defaultRows}
        rowsPerPageOptions={constants.rowsPerPageOptions}
        paginator={constants.paginator}
        scrollable={constants.scrollable}
        emptyMessage={constants.emptyMessage}
        tableStyle={constants.tableStyle}
        currentPageReportTemplate={constants.currentPageReportTemplate}
        paginatorTemplate={constants.paginatorTemplate}
        isCopyEnable={false}
        styles={styles}
        currentFilter={initialValue.showStatus}
      />

      <Toast ref={toast} position="center" />
    </>
  );
};

export default EdiPurchaseOrders;
