
const createOrderSubMenu = [
  {
    submenu: "Create Order",
    id: 21,
    path: "/ofp/orders/create"
  },
  {
    submenu: "Log Summary",
    id: 25,
    path: "/ofp/orders/logsummary"
  },
  {
    submenu: "Automated Orders",
    id: 26,
    path: "/ofp/orders/autoLog"
  },
  {
    submenu: "Edi850 Purchase Orders",
    id: 27,
    path: "/ofp/orders/edipurchaseorder"
  }
];


export const sidemenu = (showAutoLog, showEdi850PurchaseOrder) => {
  let filteredSubMenu = [...createOrderSubMenu];
  if (!showAutoLog) {
    filteredSubMenu = filteredSubMenu.filter(item => item.id !== 26);
  }

  if (!showEdi850PurchaseOrder) {
    filteredSubMenu = filteredSubMenu.filter(item => item.id !== 27);
  }
  const menu = [
    {
      heading: "Orders",
      id: 2,
      icon: "Orders",
      path: "/ofp/orders",
      routePath: "/ofp/orders",
      isExpanded: true,
      showSubMenuFlag: false,
      menuItems: filteredSubMenu
    },
    {
      heading: "Administration",
      id: 3,
      icon: "admin",
      path: "/ofp/administration/function",
      routePath: "/ofp/administration/function",
      isExpanded: true,
      showSubMenuFlag: false,
      menuItems: [
        {
          submenu: "Function Setup",
          id: 32,
          path: "/ofp/administration/function"
        }
      ]
    }
  ];

  return menu;
};
