"use client";

import React from "react";
import styles from "./spinner.module.scss";

const Spinner = () => {
    return <div className={styles.wrapldsSpinner}><div className={styles.ldsSpinner} ><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>


}
export default Spinner;