import {api} from "../api/api";

const generateXML = async (formbody, continueFlag) => {
  try {

    const config = {
        headers: {
            'content-type': 'application/json'
        }
    }
    const response = await api.post(`/api/D365?shouldContinueWithoutErrors=${continueFlag}`, formbody, config );
    return response.data?.result?.result || [];
  } catch (error) {
    throw new Error(error?.response?.data?.title);
  }
};

export default generateXML;
