import {api} from "../api/api";

export const functionSetup = async (formbody) => {
    try {
  
      const config = {
          headers: {
              'content-type': 'application/json'
          }
      }
      const response = await api.post("/api/Functions", formbody, config );
      return response.data?.result?.result || [];
    } catch (error) {
      throw new Error(error?.response?.data?.title);
    }
  };


  export const functionDelete = async (functionId) => {
    try {

      const response = await api.delete(`/api/Functions/${functionId}`);
      return response.data?.result?.result || [];
    } catch (error) {
      throw new Error(error?.response?.data?.title);
    }
  };

  export const functionSetupEdit = async (formbody,functionId) => {
    try {
      const config = {
          headers: {
              'content-type': 'application/json'
          }
      }
      const response = await api.put(`/api/Functions/${functionId}`, formbody, config );
      return response.data?.result?.result || [];
    } catch (error) {
      if(error?.response?.data?.status == 422){
        throw new Error(error?.response?.data?.validationErrors[0]?.reason);
      }
      else{
        throw new Error(error?.response?.data?.title);
      }
    }
  };

  export const FunctionTestConnection = async (formbody,ftp) =>{
    try {
      let response =""
      const config = {
          headers: {
              'content-type': 'application/json'
          }
      }
      if(ftp){
         response = await api.post("/api/VerifyConnection/FTP", formbody, config );
      }
      else{
         response = await api.post("/api/VerifyConnection/Blob", formbody, config );
      }
   
      return response.data?.result?.result || [];
    } catch (error) {
      throw new Error(error?.response?.data?.title);
    }
  }
