import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
/* import './index.scss'; */
import App from './App';
import store from "./store";
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import { LoginUserDataProvider } from './store/login';
import { FeatureFlagDataProvider } from './store/featureFlags';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppInsightsContext.Provider value={reactPlugin}>
    <LoginUserDataProvider>
        <FeatureFlagDataProvider>
    <Provider store={store}>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
     </Provider>
     </FeatureFlagDataProvider>
     </LoginUserDataProvider>
     </AppInsightsContext.Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();