import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import Spinner from "../../components/spinner/spinner";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import * as actionTypes from "../../store/actions";
import { Dropdown } from "primereact/dropdown";
import {
  getEdiFileContent,
  updateEdiPurchaseOrder
} from "../../services/ediPurchaseOrder";
import { getEdiPurchaseOrders } from "../../services/ediPurchaseOrder";
// import { reProcessEdiPurchaseOrder } from "../../services/ediPurchaseOrder";
import { format, parseISO } from "date-fns";
import {
  ediFileContentShowOptions,
  showStatusOptions
} from "../../constants/constants";
import { showStatusKeyValueOptions } from "../../constants/constants";
import { Tag } from "primereact/tag";
import { Checkbox } from "primereact/checkbox";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
// import { ediFilePurchaseOrderReProcessColumns } from "../../constants/table-columns/tableColumns";

const Table = (props) => {
  const {
    content: data,
    filterValuesData: currentFilterValuesData,
    columnsData: columns,
    tableStyle,
    paginatorTemplate,
    paginator,
    scrollable,
    rows,
    rowsPerPageOptions,
    emptyMessage,
    currentPageReportTemplate,
    rowExpansionColumnsData,
    styles
  } = props;
  const [ediPurchaseOrderTempData, setEdiPurchaseOrderTempData] =
    useState(data);
  const [selectedIds, setSelectedIds] = useState([]);
  const [ediPurchaseOrderUpdateObjs, setEdiPurchaseOrderUpdateObjs] = useState(
    []
  );
  // const [ediPOReProcessDynamicObj, setEdiPOReProcessDynamicObj] = useState([]);
  // const [ediPOReProcessStaticObj, setEdiPOReProcessStaticObj] = useState([]);
  // const [ediPOReProcessUpdateObj, setEdiPOReProcessUpdateObj] = useState([]);
  const [ediFileContentDisplay, setEdiFileContentDisplay] = useState(null);
  const [ediFileContentFormatStatus, setEdiFileContentFormatStatus] =
    useState(false);
  const [allExpanded, setAllExpanded] = useState(false);
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();
  const { getWalmartRegions } = bindActionCreators(actionCreators, dispatch);
  let regionListCodeToNamePairs = {};
  const walmartRegionList = useSelector((state) => {
    const data = state.orderReducer.walmartRegions;

    const transformedRegions = data.map((region) => {
      regionListCodeToNamePairs[region.regionCode] = region.regionName;

      return {
        label: region.regionName + " (" + region.regionCode + ")",
        value: region.regionCode
      };
    });

    return transformedRegions;
  });
  const [submitDialogVisible, setSubmitDialogVisible] = useState(false);
  // const [reProcessModalVisible, setReProcessModalVisible] = useState(false);
  const [ediFileContentModalVisible, setEdiFileContentModalVisible] =
    useState(false);

  useEffect(() => {
    getWalmartRegions();
  }, []);

  useEffect(() => {
    setEdiPurchaseOrderTempData(data);
    setSelectedIds([]);
    setEdiPurchaseOrderUpdateObjs([]);
  }, [data]);

  const processData = async () => {
    try {
      setShowSpinner(true);

      const response = await updateEdiPurchaseOrder(ediPurchaseOrderUpdateObjs);

      setShowSpinner(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Edi Purchase Order updated successfully",
        life: 3000
      });
    } catch (error) {
      setShowSpinner(false);
      toast.current.show({
        severity: "error",
        summary: "Update Failed",
        detail: error.message,
        life: 3000
      });
    }

    const result = await getEdiPurchaseOrders(currentFilterValuesData);

    setEdiPurchaseOrderTempData(result);
    setSelectedIds([]);
    setEdiPurchaseOrderUpdateObjs([]);
  };

  // const reProcessData = async () => {
  //   try {
  //     setShowSpinner(true);

  //     for (let i = 0; i < ediPOReProcessDynamicObj.length; i++) {
  //       if (
  //         ediPOReProcessDynamicObj[i].actualFulfillmentRegion ===
  //         ediPOReProcessStaticObj[i].actualFulfillmentRegion
  //       ) {
  //         setShowSpinner(false);
  //         toast.current.show({
  //           severity: "error",
  //           summary: "Validation Error",
  //           detail: "You can't submit the same previous region",
  //           life: 3000
  //         });

  //         setEdiPOReProcessUpdateObj([]);
  //         return;
  //       }

  //       const extractedObject = {
  //         ediPurchaseOrderId: ediPOReProcessDynamicObj[i].ediPurchaseOrderId,
  //         actualFulfilmentRegion:
  //           ediPOReProcessDynamicObj[i].actualFulfillmentRegion
  //       };

  //       ediPOReProcessUpdateObj.push(extractedObject);
  //     }

  //     const response = await reProcessEdiPurchaseOrder(ediPOReProcessUpdateObj);

  //     setShowSpinner(false);
  //     toast.current.show({
  //       severity: "success",
  //       summary: "Success",
  //       detail: "Edi Purchase Order updated successfully",
  //       life: 3000
  //     });
  //   } catch (error) {
  //     setShowSpinner(false);
  //     toast.current.show({
  //       severity: "error",
  //       summary: "Update Failed",
  //       detail: error.message,
  //       life: 3000
  //     });
  //   }

  //   const result = await getEdiPurchaseOrders(currentFilterValuesData);

  //   setEdiPurchaseOrderTempData(result);
  //   setSelectedIds([]);
  //   setEdiPurchaseOrderUpdateObjs([]);
  //   setEdiPOReProcessDynamicObj([]);
  //   setEdiPOReProcessUpdateObj([]);
  //   setEdiPOReProcessStaticObj([]);
  //   setReProcessModalVisible(false);
  // };

  const onActualFulfillmentRegionChange = (e, index, rowData) => {
    const updatedData = ediPurchaseOrderTempData.map((record) => {
      if (record.ediPurchaseOrderId === rowData.ediPurchaseOrderId) {
        if (e.value === undefined) {
          return { ...record, actualFulfillmentRegion: null };
        } else {
          return { ...record, actualFulfillmentRegion: e.value };
        }
      }
      return record;
    });

    setEdiPurchaseOrderTempData(updatedData);
  };

  const actualFulfillmentRegionTemplate = (rowData) => {
    const index = ediPurchaseOrderTempData.indexOf(rowData);

    if (
      rowData.actualFulfillmentRegion &&
      rowData.processingStatus !== showStatusOptions.Pending
    ) {
      return regionListCodeToNamePairs[rowData.actualFulfillmentRegion];
    } else {
      return (
        <Dropdown
          value={rowData.actualFulfillmentRegion}
          options={walmartRegionList}
          onChange={(e) => onActualFulfillmentRegionChange(e, index, rowData)}
          showClear
          placeholder="Select a region"
        />
      );
    }
  };

  function formatDateTime(isoString) {
    const date = parseISO(isoString);
    return format(date, "yyyy-MM-dd");
  }

  const ediFileCreatedDateUTCFormat = (data) => {
    return formatDateTime(data.ediFileCreatedDateUTC);
  };

  const deliveryDateFormat = (data) => {
    return formatDateTime(data.deliveryDate);
  };

  // const onProcessingStatusChange = (data) => {
  //   setEdiPOReProcessStaticObj([data]);
  //   setEdiPOReProcessDynamicObj([data]);
  //   setReProcessModalVisible(true);
  // };

  const processingStatusFormat = (data) => {
    let severityValue = "";

    switch (data.processingStatus) {
      case showStatusOptions.Pending:
        severityValue = "secondary";
        break;
      case showStatusOptions.Submitted:
        severityValue = "info";
        break;
      case showStatusOptions.Processed:
        severityValue = "success";
        break;
      case showStatusOptions.Failed:
        severityValue = "danger";
        break;
      default:
        severityValue = "";
    }

    return (
      <div>
        <Tag
          severity={severityValue}
          value={showStatusKeyValueOptions[data.processingStatus]}
          rounded
          // style={
          //   data.processingStatus === showStatusOptions.Failed
          //     ? { marginRight: "1.6rem" }
          //     : null
          // }
        ></Tag>
        {/* {(data.processingStatus === showStatusOptions.Submitted ||
          data.processingStatus === showStatusOptions.Processed ||
          data.processingStatus === showStatusOptions.Failed) && (
          <Button
            icon="pi pi-pencil"
            severity="warning"
            aria-label="Edit"
            className={`${styles.editBtn} ${styles.btns} `}
            onClick={() => onProcessingStatusChange(data)}
            tooltip="Reprocess"
          />
        )} */}
      </div>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData?.ediPurchaseOrderLineItemsList?.length > 0;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-1 pl-5 ml-5">
        <DataTable
          value={data.ediPurchaseOrderLineItemsList}
          emptyMessage={emptyMessage}
        >
          {rowExpansionColumnsData?.map((col, i) =>
            col?.columnType?.toLocaleLowerCase() === "fun" ? (
              <Column
                key={col?.field}
                field={col?.field}
                header={col?.header}
                sortable={col?.sort}
                style={{ minWidth: col?.minWidth }}
                headerStyle={{ minWidth: col.minWidth }}
              />
            ) : (
              <Column
                key={col?.field}
                field={col?.field}
                header={col?.header}
                sortable={col?.sort}
                style={{ minWidth: col?.minWidth }}
                headerStyle={{ minWidth: col.minWidth }}
              />
            )
          )}
        </DataTable>
      </div>
    );
  };

  const toggleRows = () => {
    if (allExpanded) {
      setExpandedRows(null);
      setAllExpanded(false);
    } else {
      let newExpandedRows = {};
      ediPurchaseOrderTempData.forEach(
        (p) => (newExpandedRows[`${p.ediPurchaseOrderId}`] = true)
      );
      setExpandedRows(newExpandedRows);
      setAllExpanded(true);
    }
  };

  const handleSelectAll = (e) => {
    if (e.checked) {
      const allIds = ediPurchaseOrderTempData
        .filter((item) => item.processingStatus === showStatusOptions.Pending)
        .map((item) => item.ediPurchaseOrderId);

      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const headerCheckbox = (
    <Checkbox
      onChange={handleSelectAll}
      checked={
        selectedIds.length > 0 &&
        selectedIds.length ===
          ediPurchaseOrderTempData.filter(
            (item) => item.processingStatus === showStatusOptions.Pending
          ).length
      }
      onClick={(e) => e.stopPropagation()}
    />
  );

  const onCheckboxChange = (e, rowData) => {
    let newSelectedIds = [...selectedIds];
    if (e.checked) {
      if (!newSelectedIds.includes(rowData.ediPurchaseOrderId)) {
        newSelectedIds.push(rowData.ediPurchaseOrderId);
      }
    } else {
      newSelectedIds = newSelectedIds.filter(
        (id) => id !== rowData.ediPurchaseOrderId
      );
    }
    setSelectedIds(newSelectedIds);
  };

  const checkboxColumnTemplate = (rowData) => {
    if (rowData.processingStatus === showStatusOptions.Pending) {
      return (
        <Checkbox
          onChange={(e) => onCheckboxChange(e, rowData)}
          checked={selectedIds.includes(rowData.ediPurchaseOrderId)}
          onClick={(e) => e.stopPropagation()}
        />
      );
    }
  };

  const submitEdiOrdersAccept = () => {
    setShowSpinner(true);

    for (let i = 0; i < selectedIds.length; i++) {
      const result = ediPurchaseOrderTempData.find(
        (itemRecord) => itemRecord.ediPurchaseOrderId === selectedIds[i]
      );

      if (result.actualFulfillmentRegion === null) {
        setShowSpinner(false);

        setEdiPurchaseOrderUpdateObjs([]);

        toast.current.show({
          severity: "error",
          summary: "Validation Error",
          detail: "Please select region for all selected PO's",
          life: 3000
        });

        return;
      }

      if (
        result.actualFulfillmentRegion !== null &&
        result.processingStatus === showStatusOptions.Pending
      ) {
        const extractedObject = {
          ediPurchaseOrderId: result.ediPurchaseOrderId,
          ediFileId: result.ediFileId,
          actualFulfillmentRegion: result.actualFulfillmentRegion,
          processingStatus: showStatusOptions.Submitted
        };

        ediPurchaseOrderUpdateObjs.push(extractedObject);
      }
    }

    if (ediPurchaseOrderUpdateObjs.length === 0) {
      setShowSpinner(false);

      toast.current.show({
        severity: "error",
        summary: "Validation Error",
        detail: "No PO selected",
        life: 3000
      });

      return;
    }

    setShowSpinner(false);
    setSubmitDialogVisible(true);
  };

  const submitEdiOrdersReject = () => {
    setEdiPurchaseOrderUpdateObjs([]);
    setSubmitDialogVisible(false);
  };

  const footerContent = (
    <div className="col-12 grid">
      <div className="col-12 md:col-2">
        <Button
          label="Submit"
          size="medium"
          className={styles.primaryBtn}
          onClick={submitEdiOrdersAccept}
          autoFocus
        />
      </div>
    </div>
  );

  // const reProcessHeaderElement = (
  //   <div>
  //     <div className="inline-flex align-items-center justify-content-center gap-2">
  //       <span className="font-bold white-space-nowrap">
  //         EDI850 Purchase Orders Reprocess
  //       </span>
  //     </div>
  //     <div className="text-sm" style={{ marginTop: "0.5rem", color: "red" }}>
  //       <span className="pi pi-exclamation-triangle"></span>
  //       <span style={{ marginLeft: "0.2rem" }}>
  //         Please make sure to cancel previously submitted PO in AX/ D365.
  //       </span>
  //     </div>
  //   </div>
  // );

  // const reProcessFooterContent = (
  //   <div>
  //     <Button
  //       label="Resubmit"
  //       icon="pi pi-spinner"
  //       onClick={reProcessData}
  //       autoFocus
  //     />
  //   </div>
  // );

  // const onHideEdiReProcessModal = () => {
  //   setEdiPOReProcessDynamicObj([]);
  //   setEdiPOReProcessUpdateObj([]);
  //   setEdiPOReProcessStaticObj([]);
  //   setReProcessModalVisible(false);
  // };

  // const onActualFulfillmentRegionReProcessChange = (e, rowData) => {
  //   const updatedData = ediPOReProcessDynamicObj.map((record) => {
  //     if (record.ediPurchaseOrderId === rowData.ediPurchaseOrderId) {
  //       if (e.value === undefined) {
  //         return { ...record, actualFulfillmentRegion: null };
  //       } else {
  //         return { ...record, actualFulfillmentRegion: e.value };
  //       }
  //     }
  //     return record;
  //   });

  //   setEdiPOReProcessDynamicObj(updatedData);
  // };

  // const actualFulfillmentRegionReProcessTemplate = (rowData) => {
  //   return (
  //     <Dropdown
  //       value={rowData.actualFulfillmentRegion}
  //       options={walmartRegionList}
  //       onChange={(e) => onActualFulfillmentRegionReProcessChange(e, rowData)}
  //       placeholder="Select a region"
  //     />
  //   );
  // };

  const ediPONumberFileContentFormat = (rowData) => {
    return (
      <a
        href="#"
        onClick={() =>
          ediFileContent({
            id: rowData.ediPurchaseOrderId,
            edi850FileType: ediFileContentShowOptions.EDIPurchaseOrder
          })
        }
        style={{ color: "#22c55e", textDecoration: "none" }}
      >
        {rowData.poNumber}
      </a>
    );
  };

  const ediFileNameFileContentFormat = (rowData) => {
    return (
      <a
        href="#"
        onClick={() =>
          ediFileContent({
            id: rowData.ediFileId,
            edi850FileType: ediFileContentShowOptions.EDIFile
          })
        }
        style={{ color: "#22c55e", textDecoration: "none" }}
      >
        {rowData.ediFileName}
      </a>
    );
  };

  const ediFileContent = async (ediFileContentObj) => {
    try {
      setShowSpinner(true);

      const response = await getEdiFileContent(ediFileContentObj);
      if (response.length !== 0) {
        setEdiFileContentDisplay(response);
      }

      setShowSpinner(false);
      setEdiFileContentModalVisible(true);
    } catch (error) {
      setShowSpinner(false);
      toast.current.show({
        severity: "error",
        summary: "File Content Request Failed",
        detail: error.message,
        life: 3000
      });
    }
  };

  const formatEdiFileContent = () => {
    if (ediFileContentDisplay && !ediFileContentFormatStatus) {
      const formattedEdiFileContent = ediFileContentDisplay
        .split("~")
        .join("~\n");
      setEdiFileContentDisplay(formattedEdiFileContent);
      setEdiFileContentFormatStatus(true);
    }
  };

  const ediFileContentModalHeader = (
    <div className="flex justify-content-between flex-wrap">
      <div className="flex align-items-center justify-content-center">
        <span className="font-bold white-space-nowrap">
          EDI850 File Content
        </span>
      </div>
      <div className="flex align-items-center justify-content-center">
        <Button
          label="Format"
          icon="pi pi-file-edit"
          onClick={formatEdiFileContent}
          style={{ marginRight: "1rem" }}
          autoFocus
          disabled={!ediFileContentDisplay}
        />
      </div>
    </div>
  );

  const onHideEdiFileContentModal = () => {
    setEdiFileContentDisplay(null);
    setEdiFileContentFormatStatus(false);
    setEdiFileContentModalVisible(false);
  };

  return (
    <>
      {showSpinner && <Spinner />}

      <ConfirmDialog
        visible={submitDialogVisible}
        onHide={submitEdiOrdersReject}
        message={
          ediPurchaseOrderUpdateObjs.length + " Po(s) are about to submit."
        }
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={processData}
        reject={submitEdiOrdersReject}
      />

      <Dialog
        visible={ediFileContentModalVisible}
        modal
        header={ediFileContentModalHeader}
        style={{ width: "50vw" }}
        breakpoints={{ "1024px": "75vw", "641px": "100vw" }}
        onHide={onHideEdiFileContentModal}
        maximizable
      >
        <pre style={{ whiteSpace: "pre-wrap" }}>{ediFileContentDisplay}</pre>
      </Dialog>

      {/* <Dialog
        visible={reProcessModalVisible}
        modal
        header={reProcessHeaderElement}
        footer={reProcessFooterContent}
        style={{ width: "50vw" }}
        breakpoints={{ "1024px": "75vw", "641px": "100vw" }}
        onHide={onHideEdiReProcessModal}
        maximizable
      >
        <DataTable
          value={ediPOReProcessDynamicObj}
          tableStyle={tableStyle}
          paginatorTemplate={paginatorTemplate}
          paginator={paginator}
          className={`${tableStyle.dataTable}`}
          rows={rows}
          // footer={footerContent}
          rowsPerPageOptions={rowsPerPageOptions}
          emptyMessage={emptyMessage}
          currentPageReportTemplate={currentPageReportTemplate}
          dataKey="ediPurchaseOrderId"
        >
          {ediFilePurchaseOrderReProcessColumns?.map((col, i) => (
            <Column
              key={col?.field}
              field={col?.field}
              header={col?.header}
              sortable={col?.sort}
              style={{ minWidth: col?.minWidth }}
              headerStyle={{ minWidth: col.minWidth }}
              body={
                col.field.toLocaleLowerCase() === "actualfulfillmentregion"
                  ? actualFulfillmentRegionReProcessTemplate
                  : col.value
              }
            />
          ))}
        </DataTable>
      </Dialog> */}

      <DataTable
        value={ediPurchaseOrderTempData}
        tableStyle={tableStyle}
        paginatorTemplate={paginatorTemplate}
        paginator={paginator}
        className={`${tableStyle.dataTable}`}
        rows={rows}
        footer={footerContent}
        rowsPerPageOptions={rowsPerPageOptions}
        emptyMessage={emptyMessage}
        currentPageReportTemplate={currentPageReportTemplate}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="ediPurchaseOrderId"
        scrollable={scrollable}
        scrollHeight="30vw"
      >
        <Column
          style={{ width: "10px" }}
          header={headerCheckbox}
          body={checkboxColumnTemplate}
        />

        <Column
          header={
            <Button
              className={allExpanded ? "pi pi-angle-down" : "pi pi-angle-right"}
              style={{
                fontSize: "1.3rem",
                color: "#6c757d",
                backgroundColor: "#f8f9fa",
                border: "none",
                padding: "2px"
              }}
              onClick={toggleRows}
            ></Button>
          }
          expander={allowExpansion}
          style={{ width: "10px" }}
        />

        {columns?.map((col, i) => (
          <Column
            key={col?.field}
            field={col?.field}
            header={col?.header}
            sortable={col?.sort}
            style={{ minWidth: col?.minWidth }}
            headerStyle={{ minWidth: col.minWidth }}
            body={
              col.field.toLocaleLowerCase() === "actualfulfillmentregion"
                ? actualFulfillmentRegionTemplate
                : col.field.toLocaleLowerCase() === "edifilecreateddateutc"
                ? ediFileCreatedDateUTCFormat
                : col.field.toLocaleLowerCase() === "deliverydate"
                ? deliveryDateFormat
                : col.field.toLocaleLowerCase() === "processingstatus"
                ? processingStatusFormat
                : col.field.toLocaleLowerCase() === "ponumber"
                ? ediPONumberFileContentFormat
                : col.field.toLocaleLowerCase() === "edifilename"
                ? ediFileNameFileContentFormat
                : col.value
            }
          />
        ))}
      </DataTable>

      <Toast ref={toast} position="center" />
    </>
  );
};

export default Table;
