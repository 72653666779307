import React, { createContext, useState } from 'react';

const LoginUserDataContext = createContext();
 const LoginUserDataProvider = ({ children }) => {
    const [loginUserData, setloginUserData] = useState(null);
    const contextValue = loginUserData !== undefined && loginUserData !== null ? [loginUserData, setloginUserData] : null;
    return (
        <LoginUserDataContext.Provider value={[loginUserData, setloginUserData, contextValue]}>
            {children}
        </LoginUserDataContext.Provider>
    );
};

export default LoginUserDataContext;
export {LoginUserDataProvider}