
import React, { useContext, useState, useEffect } from "react";
import { Route, Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import Layout from "./layout";
import Login from "./container/login/login"

import { loginRequest } from "./authConfig";
import { useIsAuthenticated } from '@azure/msal-react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';

import { setaxiosheader } from "./api/api-client-factory"
import LoginUserDataContext from "./store/login";
import FeatureFlagDataContext from "./store/featureFlags";
import { api, TokenExpiredInterceptor } from './api/api';
import NoAccess from "./container/noAccess/noAccess";
import axios from "axios";
import  jwtDecode  from "jwt-decode";
import Spinner from "./components/spinner/spinner";
 // You can change this line according to your choice.
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { appInsights } from './AppInsights';


function App() {
  appInsights.trackPageView({ name: 'APP.Jsx component visited' });
  const [loggedInData,setLoggedInData] = useContext(LoginUserDataContext);
  const [featureFlags, setFeatureFlags] = useContext(FeatureFlagDataContext);
  const [token, setToken] = useState(false);
  const [spinner, setSpinner] = useState(false)
  const [ isUserDataAvailable, setIsUserDataAvailable] = useState(false);
  const [showNoAccess, setShowNoAcess] = useState(false);
  const { instance, accounts } = useMsal();
  const api = axios;
 
  const getaccessToken = () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
      instance
        .acquireTokenSilent(request)
        .then((response) => {
          setToken(true);
          const bearer = `Bearer ${response.idToken}`;
          setaxiosheader(bearer);
          getUserDetails(response.idToken);
          getFeatureFlags(response.idToken)
          localStorage.setItem("token", response.idToken);
        })
        .catch((error) => {
          if (error.errorMessage.includes("interaction_in_progress")) {
            // Wait for existing interaction to complete
            appInsights.trackException({ exception: error });
          } else {
            // Handle other errors
            localStorage.clear()
            appInsights.trackException({ exception: error });
          }
        });
  };
  
  function getUserDetails(accessToken) {
    setSpinner(true)
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/Users/me`, options)
      .then((response) => response.json())
      .then((data) => {
        setSpinner(false)
        setLoggedInData(data?.result?.result)
        if(data?.isError){
          setShowNoAcess(true)
          setIsUserDataAvailable(false);
        }
        else{
          setShowNoAcess(false)
          setIsUserDataAvailable(true);
        }
      })
      .catch((error) => {
        setSpinner(false)
        appInsights.trackException({ exception: error });
      });
  }

  function getFeatureFlags(accessToken){
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/ApplicationSettings`, options)
    .then((response) => response.json())
    .then((data) => {
      localStorage.setItem("ApplicationSettings", JSON.stringify(data?.result?.result));
      setFeatureFlags(data?.result?.result)
    })
    .catch((error) => {
      setSpinner(false)
      appInsights.trackException({ exception: error });
    });
  }
  const isAuthenticated = useIsAuthenticated();
  if (isAuthenticated && !token ){
    getaccessToken()
  }
  // const decodeToken = (token)=>{
  //   try {
  //     const decodedToken = jwtDecode(token);
  //     return decodedToken;
  //   } catch (error) {
  //     return null;
  //   }
  // }
  // const getTimeDifference = (decodedToken) => {
  //   const tokenExpDate = new Date(decodedToken.exp * 1000);
  //   const currentDate = new Date();
  //   let difference = tokenExpDate.getTime() - currentDate.getTime();
  //   const minutesDifference = Math.floor(difference / (1000 * 60)); // Convert to minutes
  //   return minutesDifference;
  // }

  // const validateToken = () => {
  //   if(localStorage.getItem("token")){
  //     const decodedToken = decodeToken(localStorage.getItem("token"));
  //     const minutesDifference = getTimeDifference(decodedToken);
  //     if (minutesDifference <= 5 && minutesDifference > 0) {
  //       getaccessToken();
  //     }
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener('mousemove', validateToken);
  //   return () => {
  //     window.removeEventListener('mousemove', validateToken);
  //   };
  // },[])

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
      {!isAuthenticated && !token && <>
        <Route path='*' element={<UnauthenticatedTemplate><Navigate to='/' /></UnauthenticatedTemplate>} />
        <Route path='/' element={<UnauthenticatedTemplate><Login /></UnauthenticatedTemplate>} />
      </>}
      {isAuthenticated && token && !isUserDataAvailable && showNoAccess &&
        <Route path="/" element={<NoAccess />} /> 
      }
      {isAuthenticated && token && isUserDataAvailable && <> 
       <Route path="/ofp/*" element={<AuthenticatedTemplate><Layout /></AuthenticatedTemplate>} /> 
        <Route path="/*" element={<AuthenticatedTemplate><Navigate to='/ofp/orders/create' /></AuthenticatedTemplate>} />
      </>}
        </Route>
    )
  );
  return (
    <div className="App">
      {spinner && <Spinner />}
      {isAuthenticated && token && isUserDataAvailable && <TokenExpiredInterceptor accounts={accounts}/>}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
