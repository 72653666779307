import {api} from "../api/api";

const fetchXMLFile = async (params) => {
    try {
       const response = await api.get("/api/LogSummary/XmlContent", {params});
       return response.data?.result?.result || [];
      } catch (error) {
        throw new Error(error?.response?.data?.title);
      }
};

export default fetchXMLFile;