import {api} from "../api/api";

const orderSummaryApi = async (formbody) => {
  try {

    const config = {
        headers: {
            'content-type': 'application/json'
        }
    }
    const response = await api.post("/api/Orders/Summary", formbody, config );
    return response.data?.result?.result || [];
  } catch (error) {
    throw new Error(error?.response?.data?.title);
  }
};

export default orderSummaryApi;