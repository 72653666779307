import React, { createContext, useState } from 'react';

const FeatureFlagDataContext = createContext();
 const FeatureFlagDataProvider = ({ children }) => {
    const [featureFlagData, setFeatureFlagData] = useState(null);
    const contextValue = featureFlagData !== undefined && featureFlagData !== null ? [featureFlagData, setFeatureFlagData] : null;
    return (
        <FeatureFlagDataContext.Provider value={[featureFlagData, setFeatureFlagData, contextValue]}>
            {children}
        </FeatureFlagDataContext.Provider>
    );
};

export default FeatureFlagDataContext;
export {FeatureFlagDataProvider}