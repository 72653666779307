import {api} from "../api/api";

export const logSummaryApi = async (params) => {
    try {
        const response = await api.get("/api/LogSummary", { params });
        return response.data?.result?.result || [];
      } catch (error) {
        throw new Error(error?.response?.data?.title);
      }
};

export const reProcessApi = async (fileName) =>{
  try {
    const response = await api.put(`/api/Orders/Re-process/${fileName}`);
    return response.data?.result?.result || [];
  } catch (error) {
    throw new Error(error?.response?.data?.title);
  }
}