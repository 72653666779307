import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import getFunctionConfigurationDetails from "../../services/functionXMLConfigurations";
import { useState ,useEffect} from "react";
import {sortData} from "../../constants/dataSort";
import { appInsights } from '../../AppInsights';

const MapFileInfoForm = (props) => {
  const showFtp = props?.showFtp;
  const copyFunctionList = sortData('functionName',props?.masterData?.functions);
  
  const changeTab = (tabIndex) => {
    props?.tabChange(tabIndex);
  };

  const onSelectFunction = (event) => {
    try {
      resetConfugurations();
      const { value } = event.target;
      props?.setFieldValue("copyFrom", value);
      getConfigurationData(
        value?.functionConfigurations?.functionConfigurationId
      );
      prepopulateFields(value?.functionConfigurations);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Error while selecting a Function from 'copy from' dropdown",
        },
        measurements: {
          severityLevel: 2 
        }
      });
    }
  };

  const getConfigurationData = async (configurationId) => {
    try {
      const configurationData = await getFunctionConfigurationDetails(
        configurationId
      );
      prepopulateConfigurationFields(configurationData);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Error whilefetching configuration data when copy from is selected",
        },
        measurements: {
          severityLevel: 2 
        }
      });
    }
  };

  const prepopulateFields = (value) => {
    props?.setFieldValue("storeInHeaderRow", value?.storeInHeaderRow);
    props?.setFieldValue("storeInHeaderColumn", value?.storeInHeaderColumn);
    props?.setFieldValue(
      "storeInHeaderLabel",
      value?.storeLabel ? value?.storeLabel : ""
    );
    props?.setFieldValue("dateInHeaderRow", value?.dateInHeaderRow);
    props?.setFieldValue("dateInHeaderColumn", value?.dateInHeaderColumn);
    props?.setFieldValue(
      "dateInHeaderLabel",
      value?.dateLabel ? value?.dateLabel : ""
    );
    props?.setFieldValue("poInHeaderRow", value?.poinHeaderRow);
    props?.setFieldValue("poInHeaderColumn", value?.poinHeaderColumn);
    props?.setFieldValue(
      "poInHeaderLabel",
      value?.polabel ? value?.polabel : ""
    );

    props?.setFieldValue("loadDescription", value?.loadDescription);
    props?.setFieldValue("loadWRHS", value?.loadWrhssalesHeader);
    props?.setFieldValue("allowDuplicates", value?.allowDuplicateItems);
    props?.setFieldValue(
      "dunnPrefix",
      value?.dunnPrefix ? value?.dunnPrefix : ""
    );
    props?.setFieldValue("dunnSufix", value?.dunnSufix ? value?.dunnSufix : "");
    props?.setFieldValue("itemXref", value?.itemXrefLenght);
    props?.setFieldValue(
      "defaultWRHS",
      value?.defaultWrhs ? value?.defaultWrhs : ""
    );
    props?.setFieldValue(
      "tabularHeaderStartsRow",
      value?.dataStartRow ? value?.dataStartRow : ""
    );
    props?.setFieldValue("keywords", value?.keywords ? value?.keywords : "");
  };
  const resetConfugurations = () => {
    props?.setFieldValue("colShipReq", "");
    props?.setFieldValue("headerNameShipReq", "");
    props?.setFieldValue("altFirstHeaderShipReq", "");
    props?.setFieldValue("altSecondHeaderShipReq", "");
    props?.setFieldValue("colReceiptDate", "");
    props?.setFieldValue("headerNameReceiptDate", "");
    props?.setFieldValue("altFirstHeaderReceiptDate", "");
    props?.setFieldValue("altSecondHeaderReceiptDate", "");
    props?.setFieldValue("colShipTo", "");
    props?.setFieldValue("headerNameShipTo", "");
    props?.setFieldValue("altFirstHeaderShipTo", "");
    props?.setFieldValue("altSecondHeaderShipTo", "");
    props?.setFieldValue("colPO", "");
    props?.setFieldValue("headerNamePO", "");
    props?.setFieldValue("altFirstHeaderPO", "");
    props?.setFieldValue("altSecondHeaderPO", "");
    props?.setFieldValue("colItem", "");
    props?.setFieldValue("headerNameItem", "");
    props?.setFieldValue("altFirstHeaderItem", "");
    props?.setFieldValue("altSecondHeaderItem", "");
    props?.setFieldValue("colQty", "");
    props?.setFieldValue("headerNameQty", "");
    props?.setFieldValue("altFirstHeaderQty", "");
    props?.setFieldValue("altSecondHeaderQty", "");
    props?.setFieldValue("colUOM", "");
    props?.setFieldValue("headerNameUOM", "");
    props?.setFieldValue("altFirstHeaderUOM", "");
    props?.setFieldValue("altSecondHeaderUOM", "");
    props?.setFieldValue("colDescription", "");
    props?.setFieldValue("headerNameDescription", "");
    props?.setFieldValue("altFirstHeaderDescription", "");
    props?.setFieldValue("altSecondHeaderDescription", "");
    props?.setFieldValue("colRoute", "");
    props?.setFieldValue("headerNameRoute", "");
    props?.setFieldValue("altFirstHeaderRoute", "");
    props?.setFieldValue("altSecondHeaderRoute", "");
    props?.setFieldValue("colStop", "");
    props?.setFieldValue("headerNameStop", "");
    props?.setFieldValue("altFirstHeaderStop", "");
    props?.setFieldValue("altSecondHeaderStop", "");
    props?.setFieldValue("colWRHS", "");
    props?.setFieldValue("headerNameWRHS", "");
    props?.setFieldValue("altFirstHeaderWRHS", "");
    props?.setFieldValue("altSecondHeaderWRHS", "");
    props?.setFieldValue("colInvBatchID", "");
    props?.setFieldValue("headerNameInvBatchID", "");
    props?.setFieldValue("altFirstHeaderInvBatchID", "");
    props?.setFieldValue("altSecondHeaderInvBatchID", "");
    props?.setFieldValue("colPurchaseOrder", "");
    props?.setFieldValue("headerNamePurchaseOrder", "");
    props?.setFieldValue("altFirstHeaderPurchaseOrder", "");
    props?.setFieldValue("altSecondHeaderPurchaseOrder", "");
  };
  const prepopulateConfigurationFields = (value) => {
    if (value?.length > 0) {
      value?.map((item) => {
        if (item?.edixmlColumnId === 1) {
          props?.setFieldValue("colShipReq", item?.columnIndex);
          props?.setFieldValue(
            "headerNameShipReq",
            item?.headerName ? item?.headerName : ""
          );
          props?.setFieldValue(
            "altFirstHeaderShipReq",
            item?.alternateHeaderName1 ? item?.alternateHeaderName1 : ""
          );
          props?.setFieldValue(
            "altSecondHeaderShipReq",
            item?.alternateHeaderName2 ? item?.alternateHeaderName2 : ""
          );
        } else if (item?.edixmlColumnId === 2) {
          props?.setFieldValue("colReceiptDate", item?.columnIndex);
          props?.setFieldValue(
            "headerNameReceiptDate",
            item?.headerName ? item?.headerName : ""
          );
          props?.setFieldValue(
            "altFirstHeaderReceiptDate",
            item?.alternateHeaderName1 ? item?.alternateHeaderName1 : ""
          );
          props?.setFieldValue(
            "altSecondHeaderReceiptDate",
            item?.alternateHeaderName2 ? item?.alternateHeaderName2 : ""
          );
        } else if (item?.edixmlColumnId === 3) {
          props?.setFieldValue("colShipTo", item?.columnIndex);
          props?.setFieldValue(
            "headerNameShipTo",
            item?.headerName ? item?.headerName : ""
          );
          props?.setFieldValue(
            "altFirstHeaderShipTo",
            item?.alternateHeaderName1 ? item?.alternateHeaderName1 : ""
          );
          props?.setFieldValue(
            "altSecondHeaderShipTo",
            item?.alternateHeaderName2 ? item?.alternateHeaderName2 : ""
          );
        } else if (item?.edixmlColumnId === 4) {
          props?.setFieldValue("colPO", item?.columnIndex);
          props?.setFieldValue(
            "headerNamePO",
            item?.headerName ? item?.headerName : ""
          );
          props?.setFieldValue(
            "altFirstHeaderPO",
            item?.alternateHeaderName1 ? item?.alternateHeaderName1 : ""
          );
          props?.setFieldValue(
            "altSecondHeaderPO",
            item?.alternateHeaderName2 ? item?.alternateHeaderName2 : ""
          );
        } else if (item?.edixmlColumnId === 5) {
          props?.setFieldValue("colItem", item?.columnIndex);
          props?.setFieldValue(
            "headerNameItem",
            item?.headerName ? item?.headerName : ""
          );
          props?.setFieldValue(
            "altFirstHeaderItem",
            item?.alternateHeaderName1 ? item?.alternateHeaderName1 : ""
          );
          props?.setFieldValue(
            "altSecondHeaderItem",
            item?.alternateHeaderName2 ? item?.alternateHeaderName2 : ""
          );
        } else if (item?.edixmlColumnId === 6) {
          props?.setFieldValue("colQty", item?.columnIndex);
          props?.setFieldValue(
            "headerNameQty",
            item?.headerName ? item?.headerName : ""
          );
          props?.setFieldValue(
            "altFirstHeaderQty",
            item?.alternateHeaderName1 ? item?.alternateHeaderName1 : ""
          );
          props?.setFieldValue(
            "altSecondHeaderQty",
            item?.alternateHeaderName2 ? item?.alternateHeaderName2 : ""
          );
        } else if (item?.edixmlColumnId === 7) {
          props?.setFieldValue("colUOM", item?.columnIndex);
          props?.setFieldValue(
            "headerNameUOM",
            item?.headerName ? item?.headerName : ""
          );
          props?.setFieldValue(
            "altFirstHeaderUOM",
            item?.alternateHeaderName1 ? item?.alternateHeaderName1 : ""
          );
          props?.setFieldValue(
            "altSecondHeaderUOM",
            item?.alternateHeaderName2 ? item?.alternateHeaderName2 : ""
          );
        } else if (item?.edixmlColumnId === 8) {
          props?.setFieldValue("colDescription", item?.columnIndex);
          props?.setFieldValue(
            "headerNameDescription",
            item?.headerName ? item?.headerName : ""
          );
          props?.setFieldValue(
            "altFirstHeaderDescription",
            item?.alternateHeaderName1 ? item?.alternateHeaderName1 : ""
          );
          props?.setFieldValue(
            "altSecondHeaderDescription",
            item?.alternateHeaderName2 ? item?.alternateHeaderName2 : ""
          );
        } else if (item?.edixmlColumnId === 9) {
          props?.setFieldValue("colRoute", item?.columnIndex);
          props?.setFieldValue(
            "headerNameRoute",
            item?.headerName ? item?.headerName : ""
          );
          props?.setFieldValue(
            "altFirstHeaderRoute",
            item?.alternateHeaderName1 ? item?.alternateHeaderName1 : ""
          );
          props?.setFieldValue(
            "altSecondHeaderRoute",
            item?.alternateHeaderName2 ? item?.alternateHeaderName2 : ""
          );
        } else if (item?.edixmlColumnId === 10) {
          props?.setFieldValue("colStop", item?.columnIndex);
          props?.setFieldValue(
            "headerNameStop",
            item?.headerName ? item?.headerName : ""
          );
          props?.setFieldValue(
            "altFirstHeaderStop",
            item?.alternateHeaderName1 ? item?.alternateHeaderName1 : ""
          );
          props?.setFieldValue(
            "altSecondHeaderStop",
            item?.alternateHeaderName2 ? item?.alternateHeaderName2 : ""
          );
        } else if (item?.edixmlColumnId === 11) {
          props?.setFieldValue("colWRHS", item?.columnIndex);
          props?.setFieldValue(
            "headerNameWRHS",
            item?.headerName ? item?.headerName : ""
          );
          props?.setFieldValue(
            "altFirstHeaderWRHS",
            item?.alternateHeaderName1 ? item?.alternateHeaderName1 : ""
          );
          props?.setFieldValue(
            "altSecondHeaderWRHS",
            item?.alternateHeaderName2 ? item?.alternateHeaderName2 : ""
          );
        } else if (item?.edixmlColumnId === 12) {
          props?.setFieldValue("colInvBatchID", item?.columnIndex);
          props?.setFieldValue(
            "headerNameInvBatchID",
            item?.headerName ? item?.headerName : ""
          );
          props?.setFieldValue(
            "altFirstHeaderInvBatchID",
            item?.alternateHeaderName1 ? item?.alternateHeaderName1 : ""
          );
          props?.setFieldValue(
            "altSecondHeaderInvBatchID",
            item?.alternateHeaderName2 ? item?.alternateHeaderName2 : ""
          );
        } else {
          props?.setFieldValue("colPurchaseOrder", item?.columnIndex);
          props?.setFieldValue(
            "headerNamePurchaseOrder",
            item?.headerName ? item?.headerName : ""
          );
          props?.setFieldValue(
            "altFirstHeaderPurchaseOrder",
            item?.alternateHeaderName1 ? item?.alternateHeaderName1 : ""
          );
          props?.setFieldValue(
            "altSecondHeaderPurchaseOrder",
            item?.alternateHeaderName2 ? item?.alternateHeaderName2 : ""
          );
        }
      });
    }
  };

  return (
    <div className="grid">
      <div className="col-12 md:col-6">
        <h5 className={props?.styles.Formheader}>
          Import File Header Information
        </h5>
      </div>
      <div className="col-12 md:col-6 md:text-right">
        <label htmlFor="copyFrom">
          <span className={props?.styles.formLabel}>Copy From</span>
        </label>
        <Dropdown
          name="copyFrom"
          filter="true"
          filterBy="functionName"
          sortable="true"
          placeholder="Select Function"
          value={props?.values?.copyFrom}
          disabled={props?.isReadonly}
          options={copyFunctionList}
          optionLabel="functionName"
          onChange={(e) => onSelectFunction(e, props?.setFieldValue)}
          className={props?.styles.statusdropdown}
        />
      </div>
      <div className="col-12">
        <div className="grid">
          <div className="col-12 md:col-3">
            <div className={props?.styles.formLabelContainer}>
              <label htmlFor="storeInHeaderRow">
                <span className={props?.styles.formLabel}>
                  Store in Header Row
                </span>
              </label>
            </div>
            <InputNumber
              id="storeInHeaderRow"
              value={props?.values.storeInHeaderRow}
              onValueChange={props?.changeEvent}
              onBlur={props?.blurEvent}
              showButtons
              min={0}
              max={50}
              className={`${props?.styles.formControl}`}
              disabled={props?.isReadonly}
            />
            <div>
              {props?.errors.storeInHeaderRow &&
                props?.touched.storeInHeaderRow && (
                  <span className={props?.styles?.errorMsg}>
                    {props?.errors.storeInHeaderRow}
                  </span>
                )}
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className={props?.styles.formLabelContainer}>
              <label htmlFor="storeInHeaderColumn">
                <span className={props?.styles.formLabel}>
                  Store in Header Column
                </span>
              </label>
            </div>
            <InputNumber
              id="storeInHeaderColumn"
              value={props?.values.storeInHeaderColumn}
              onValueChange={props?.changeEvent}
              onBlur={props?.blurEvent}
              showButtons
              min={0}
              max={50}
              className={`${props?.styles.formControl}`}
              disabled={props?.isReadonly}
            />
            <div>
              {props?.errors.storeInHeaderColumn &&
                props?.touched.storeInHeaderColumn && (
                  <span className={props?.styles?.errorMsg}>
                    {props?.errors.storeInHeaderColumn}
                  </span>
                )}
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className={props?.styles.formLabelContainer}>
              <label htmlFor="storeInHeaderLabel">
                <span className={props?.styles.formLabel}>Label</span>
              </label>
            </div>
            <InputText
              id="storeInHeaderLabel"
              name="storeInHeaderLabel"
              value={props?.values?.storeInHeaderLabel}
              onChange={props?.changeEvent}
              className={`${props?.styles.inputField} ${props?.styles.formControl}`}
              disabled={props?.isReadonly}
            />
            <div>
              {props?.errors.storeInHeaderLabel &&
                props?.touched.storeInHeaderLabel && (
                  <span className={props?.styles?.errorMsg}>
                    Label must be at most 50 characters
                  </span>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="grid">
          <div className="col-12 md:col-3">
            <div className={props?.styles.formLabelContainer}>
              <label htmlFor="dateInHeaderRow">
                <span className={props?.styles.formLabel}>
                  Date in Header Row
                </span>
              </label>
            </div>
            <InputNumber
              id="dateInHeaderRow"
              value={props?.values.dateInHeaderRow}
              onValueChange={props?.changeEvent}
              onBlur={props?.blurEvent}
              showButtons
              min={0}
              max={50}
              className={`${props?.styles.formControl}`}
              disabled={props?.isReadonly}
            />
            <div>
              {props?.errors.dateInHeaderRow &&
                props?.touched.dateInHeaderRow && (
                  <span className={props?.styles?.errorMsg}>
                    {props?.errors.dateInHeaderRow}
                  </span>
                )}
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className={props?.styles.formLabelContainer}>
              <label htmlFor="dateInHeaderColumn">
                <span className={props?.styles.formLabel}>
                  {" "}
                  Date in Header Column
                </span>
              </label>
            </div>
            <InputNumber
              id="dateInHeaderColumn"
              value={props?.values.dateInHeaderColumn}
              onValueChange={props?.changeEvent}
              onBlur={props?.blurEvent}
              showButtons
              min={0}
              max={50}
              className={`${props?.styles.formControl}`}
              disabled={props?.isReadonly}
            />
            <div>
              {props?.errors.dateInHeaderColumn &&
                props?.touched.dateInHeaderColumn && (
                  <span className={props?.styles?.errorMsg}>
                    {props?.errors.dateInHeaderColumn}
                  </span>
                )}
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className={props?.styles.formLabelContainer}>
              <label htmlFor="dateInHeaderLabel">
                <span className={props?.styles.formLabel}>Label</span>
              </label>
            </div>
            <InputText
              id="dateInHeaderLabel"
              name="dateInHeaderLabel"
              value={props?.values?.dateInHeaderLabel}
              onChange={props?.changeEvent}
              className={`${props?.styles.inputField} ${props?.styles.formControl}`}
              disabled={props?.isReadonly}
            />
            <div>
              {props?.errors.dateInHeaderLabel &&
                props?.touched.dateInHeaderLabel && (
                  <span className={props?.styles?.errorMsg}>
                     Label must be at most 50 characters
                  </span>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="grid">
          <div className="col-12 md:col-3">
            <div className={props?.styles.formLabelContainer}>
              <label htmlFor="poInHeaderRow">
                <span className={props?.styles.formLabel}>
                  {" "}
                  PO# in Header Row
                </span>
              </label>
            </div>
            <InputNumber
              id="poInHeaderRow"
              value={props?.values.poInHeaderRow}
              onValueChange={props?.changeEvent}
              onBlur={props?.blurEvent}
              showButtons
              min={0}
              max={50}
              className={`${props?.styles.formControl}`}
              disabled={props?.isReadonly}
            />
            <div>
              {props?.errors.poInHeaderRow && props?.touched.poInHeaderRow && (
                <span className={props?.styles?.errorMsg}>
                  {props?.errors.poInHeaderRow}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className={props?.styles.formLabelContainer}>
              <label htmlFor="poInHeaderColumn">
                <span className={props?.styles.formLabel}>
                  PO# in Header Column
                </span>
              </label>
            </div>
            <InputNumber
              id="poInHeaderColumn"
              value={props?.values.poInHeaderColumn}
              onValueChange={props?.changeEvent}
              onBlur={props?.blurEvent}
              showButtons
              min={0}
              max={50}
              className={`${props?.styles.formControl}`}
              disabled={props?.isReadonly}
            />
            <div>
              {props?.errors.poInHeaderColumn &&
                props?.touched.poInHeaderColumn && (
                  <span className={props?.styles?.errorMsg}>
                    {props?.errors.poInHeaderColumn}
                  </span>
                )}
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className={props?.styles.formLabelContainer}>
              <label htmlFor="region">
                <span className={props?.styles.formLabel}>Label</span>
              </label>
            </div>
            <InputText
              id="poInHeaderLabel"
              name="poInHeaderLabel"
              value={props?.values?.poInHeaderLabel}
              onChange={props?.changeEvent}
              className={`${props?.styles.inputField} ${props?.styles.formControl}`}
              disabled={props?.isReadonly}
            />
            <div>
              {props?.errors.poInHeaderLabel &&
                props?.touched.poInHeaderLabel && (
                  <span className={props?.styles?.errorMsg}>
                      Label must be at most 50 characters
                  </span>
                )}
            </div>
          </div>
          <div className="col-12">
            <div className="grid">
              <div className="col-12 md:col-3">
                <div
                  className={`flex align-items-center ${props?.styles.checkBoxSpacing}`}
                >
                  <Checkbox
                    inputId="loadDescription"
                    name="loadDescription"
                    checked={props?.values.loadDescription}
                    onChange={props?.changeEvent}
                    onBlur={props?.blurEvent}
                    disabled={props?.isReadonly}
                  />
                  <label htmlFor="loadDescription" className="ml-2">
                    Load Description in AX Text
                  </label>
                </div>
              </div>
              <div className="col-12 md:col-3">
                <div
                  className={`flex align-items-center ${props?.styles.checkBoxSpacing}`}
                >
                  <Checkbox
                    inputId="loadWRHS"
                    name="loadWRHS"
                    checked={props?.values.loadWRHS}
                    onChange={props?.changeEvent}
                    onBlur={props?.blurEvent}
                    disabled={props?.isReadonly}
                  />
                  <label htmlFor="loadWRHS" className="ml-2">
                    Load WRHS in Sales Header
                  </label>
                </div>
              </div>
              <div className="col-12 md:col-3">
                <div
                  className={`flex align-items-center ${props?.styles.checkBoxSpacing}`}
                >
                  <Checkbox
                    inputId="allowDuplicates"
                    name="allowDuplicates"
                    checked={props?.values.allowDuplicates}
                    onChange={props?.changeEvent}
                    onBlur={props?.blurEvent}
                    disabled={props?.isReadonly}
                  />
                  <label htmlFor="allowDuplicates" className="ml-2">
                    Allow Duplicate Items
                  </label>
                </div>
              </div>
              <div className="col-12 md:col-3">
                <div
                  className={`flex align-items-center ${props?.styles.checkBoxSpacing}`}
                >
                <input id="multiStore" name="multiStore" type="checkbox"  checked={props?.values.multiStore} onChange={props?.changeEvent} onBlur={props?.blurEvent} disabled className={props?.styles.disabledCheckBox}></input>
                  <label htmlFor="multiStore" className="ml-2">
                    Multi Store# and Qty Column
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="grid">
              <div className="col-12 md:col-3">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="dunnPrefix">
                    <span className={props?.styles.formLabel}>Dunn Prefix</span>
                  </label>
                </div>
                <InputText
                  id="dunnPrefix"
                  name="dunnPrefix"
                  value={props?.values?.dunnPrefix}
                  onChange={props?.changeEvent}
                  className={`${props?.styles.inputField} ${props?.styles.formControl}`}
                  disabled={props?.isReadonly}
                />
                <div>
                  {props?.errors.dunnPrefix && props?.touched.dunnPrefix && (
                    <span className={props?.styles?.errorMsg}>
                        Dunn Prefix must be at most 50 characters
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-3">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="dunnSufix">
                    <span className={props?.styles.formLabel}>Dunn Suffix</span>
                  </label>
                </div>
                <InputText
                  id="dunnSufix"
                  name="dunnSufix"
                  value={props?.values?.dunnSufix}
                  onChange={props?.changeEvent}
                  className={`${props?.styles.inputField} ${props?.styles.formControl}`}
                  disabled={props?.isReadonly}
                />
                <div>
                  {props?.errors.dunnSufix && props?.touched.dunnSufix && (
                    <span className={props?.styles?.errorMsg}>
                      Dunn Sufix must be at most 50 characters
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-3">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="itemXref">
                    <span className={props?.styles.formLabel}>
                      Item Xref Length
                    </span>
                  </label>
                </div>
                <InputNumber
                  id="itemXref"
                  value={props?.values.itemXref}
                  onValueChange={props?.changeEvent}
                  onBlur={props?.blurEvent}
                  showButtons
                  min={0}
                  max={100}
                  className={`${props?.styles.formControl}`}
                  disabled={props?.isReadonly}
                />
                <div>
                  {props?.errors.itemXref && props?.touched.itemXref && (
                    <span className={props?.styles?.errorMsg}>
                      {props?.errors.itemXref}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-3">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="defaultWRHS">
                    <span className={props?.styles.formLabel}>
                      Default WRHS
                    </span>
                  </label>
                </div>
                <InputText
                  id="defaultWRHS"
                  name="defaultWRHS"
                  value={props?.values?.defaultWRHS}
                  onChange={props?.changeEvent}
                  className={`${props?.styles.inputField} ${props?.styles.formControl}`}
                  disabled={props?.isReadonly}
                />
                <div>
                  {props?.errors.defaultWRHS && props?.touched.defaultWRHS && (
                    <span className={props?.styles?.errorMsg}>
                    Default WRHS must be at most 50 characters
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="grid">
              <div className="col-12 md:col-3">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="tabularHeaderStartsRow">
                    <span className={props?.styles.formLabel}>
                      Tabular Header Row Starts at Row
                    </span>
                  </label>
                </div>
                <InputText
                  keyfilter="int"
                  id="tabularHeaderStartsRow"
                  disabled={props?.isReadonly}
                  name="tabularHeaderStartsRow"
                  value={props?.values?.tabularHeaderStartsRow}
                  onChange={props?.changeEvent}
                  className={`${props?.styles.inputField} ${props?.styles.formControl}`}
                />
                <div>
                  {props?.errors.tabularHeaderStartsRow &&
                    props?.touched.tabularHeaderStartsRow && (
                      <span className={props?.styles?.errorMsg}>
                        Tabular Header Row must be less than or equal to 1000
                      </span>
                    )}
                </div>
              </div>
              <div className="col-12 md:col-9">
                <div className={props?.styles.formLabelContainer}>
                  <label htmlFor="keywords">
                    <span className={props?.styles.formLabel}>Keywords (,)</span>
                  </label>
                </div>
                <InputTextarea
                autoResize
                  rows={5}
                  cols={30}
                  id="keywords"
                  value={props?.values.keywords}
                  onChange={props?.changeEvent}
                  onBlur={props?.blurEvent}
                  className={`${props?.styles.formControl}`}
                  disabled={props?.isReadonly}
                />
                <div>
                  {props?.errors.keywords && props?.touched.keywords && (
                    <span className={props?.styles?.errorMsg}>
                      {props?.errors.keywords}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className={props?.styles?.gridContainer}>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  ></div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    Ship Request Date
                  </div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    Receipt Date
                  </div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    Ship to
                  </div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    PO #
                  </div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    Item #
                  </div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    Qty
                  </div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    UOM
                  </div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    Description
                  </div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    Route
                  </div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    Stop
                  </div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    WRHS
                  </div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    InvBatchID
                  </div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    Purchase Order
                  </div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    Col #
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputNumber
                      id="colShipReq"
                      value={props?.values.colShipReq}
                      onValueChange={props?.changeEvent}
                      onBlur={props?.blurEvent}
                      showButtons
                      min={0}
                      max={1000}
                      className={`${props?.styles.formControl}`}
                      disabled={props?.isReadonly}
                    />
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputNumber
                      id="colReceiptDate"
                      value={props?.values.colReceiptDate}
                      onValueChange={props?.changeEvent}
                      onBlur={props?.blurEvent}
                      showButtons
                      min={0}
                      max={1000}
                      className={`${props?.styles.formControl}`}
                      disabled={props?.isReadonly}
                    />
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputNumber
                      id="colShipTo"
                      value={props?.values.colShipTo}
                      onValueChange={props?.changeEvent}
                      onBlur={props?.blurEvent}
                      showButtons
                      min={0}
                      max={1000}
                      className={`${props?.styles.formControl}`}
                      disabled={props?.isReadonly}
                    />
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputNumber
                      id="colPO"
                      value={props?.values.colPO}
                      onValueChange={props?.changeEvent}
                      onBlur={props?.blurEvent}
                      showButtons
                      min={0}
                      max={1000}
                      className={`${props?.styles.formControl}`}
                      disabled={props?.isReadonly}
                    />
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputNumber
                      id="colItem"
                      value={props?.values.colItem}
                      onValueChange={props?.changeEvent}
                      onBlur={props?.blurEvent}
                      showButtons
                      min={0}
                      max={1000}
                      className={`${props?.styles.formControl}`}
                      disabled={props?.isReadonly}
                    />
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputNumber
                      id="colQty"
                      value={props?.values.colQty}
                      onValueChange={props?.changeEvent}
                      onBlur={props?.blurEvent}
                      showButtons
                      min={0}
                      max={1000}
                      className={`${props?.styles.formControl}`}
                      disabled={props?.isReadonly}
                    />
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputNumber
                      id="colUOM"
                      value={props?.values.colUOM}
                      onValueChange={props?.changeEvent}
                      onBlur={props?.blurEvent}
                      showButtons
                      min={0}
                      max={1000}
                      className={`${props?.styles.formControl}`}
                      disabled={props?.isReadonly}
                    />
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputNumber
                      id="colDescription"
                      value={props?.values.colDescription}
                      onValueChange={props?.changeEvent}
                      onBlur={props?.blurEvent}
                      showButtons
                      min={0}
                      max={1000}
                      className={`${props?.styles.formControl}`}
                      disabled={props?.isReadonly}
                    />
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputNumber
                      id="colRoute"
                      value={props?.values.colRoute}
                      onValueChange={props?.changeEvent}
                      onBlur={props?.blurEvent}
                      showButtons
                      min={0}
                      max={1000}
                      className={`${props?.styles.formControl}`}
                      disabled={props?.isReadonly}
                    />
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputNumber
                      id="colStop"
                      value={props?.values.colStop}
                      onValueChange={props?.changeEvent}
                      onBlur={props?.blurEvent}
                      showButtons
                      min={0}
                      max={1000}
                      className={`${props?.styles.formControl}`}
                      disabled={props?.isReadonly}
                    />
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputNumber
                      id="colWRHS"
                      value={props?.values.colWRHS}
                      onValueChange={props?.changeEvent}
                      onBlur={props?.blurEvent}
                      showButtons
                      min={0}
                      max={1000}
                      className={`${props?.styles.formControl}`}
                      disabled={props?.isReadonly}
                    />
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputNumber
                      id="colInvBatchID"
                      value={props?.values.colInvBatchID}
                      onValueChange={props?.changeEvent}
                      onBlur={props?.blurEvent}
                      showButtons
                      min={0}
                      max={1000}
                      className={`${props?.styles.formControl}`}
                      disabled={props?.isReadonly}
                    />
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputNumber
                      id="colPurchaseOrder"
                      value={props?.values.colPurchaseOrder}
                      onValueChange={props?.changeEvent}
                      onBlur={props?.blurEvent}
                      showButtons
                      min={0}
                      max={1000}
                      className={`${props?.styles.formControl}`}
                      disabled={props?.isReadonly}
                    />
                  </div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    Header Name
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="headerNameShipReq"
                      name="headerNameShipReq"
                      value={props?.values?.headerNameShipReq}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                      {props?.errors.headerNameShipReq && props?.touched.headerNameShipReq && <span className={props?.styles?.errorMsg}>Header must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="headerNameReceiptDate"
                      name="headerNameReceiptDate"
                      value={props?.values?.headerNameReceiptDate}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                     {props?.errors.headerNameReceiptDate && props?.touched.headerNameReceiptDate && <span className={props?.styles?.errorMsg}>Header must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="headerNameShipTo"
                      name="headerNameShipTo"
                      value={props?.values?.headerNameShipTo}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                     {props?.errors.headerNameShipTo && props?.touched.headerNameShipTo && <span className={props?.styles?.errorMsg}>Header must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="headerNamePO"
                      name="headerNamePO"
                      value={props?.values?.headerNamePO}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.headerNamePO && props?.touched.headerNamePO && <span className={props?.styles?.errorMsg}>Header must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="headerNameItem"
                      name="headerNameItem"
                      value={props?.values?.headerNameItem}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                     {props?.errors.headerNameItem && props?.touched.headerNameItem && <span className={props?.styles?.errorMsg}>Header must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="headerNameQty"
                      name="headerNameQty"
                      value={props?.values?.headerNameQty}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                     {props?.errors.headerNameQty && props?.touched.headerNameQty && <span className={props?.styles?.errorMsg}>Header must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="headerNameUOM"
                      name="headerNameUOM"
                      value={props?.values?.headerNameUOM}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.headerNameUOM && props?.touched.headerNameUOM && <span className={props?.styles?.errorMsg}>Header must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="headerNameDescription"
                      name="headerNameDescription"
                      value={props?.values?.headerNameDescription}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.headerNameDescription && props?.touched.headerNameDescription && <span className={props?.styles?.errorMsg}>Header must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="headerNameRoute"
                      name="headerNameRoute"
                      value={props?.values?.headerNameRoute}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.headerNameRoute && props?.touched.headerNameRoute && <span className={props?.styles?.errorMsg}>Header must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="headerNameStop"
                      name="headerNameStop"
                      value={props?.values?.headerNameStop}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.headerNameStop && props?.touched.headerNameStop && <span className={props?.styles?.errorMsg}>Header must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="headerNameWRHS"
                      name="headerNameWRHS"
                      value={props?.values?.headerNameWRHS}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.headerNameWRHS && props?.touched.headerNameWRHS && <span className={props?.styles?.errorMsg}>Header must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="headerNameInvBatchID"
                      name="headerNameInvBatchID"
                      value={props?.values?.headerNameInvBatchID}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.headerNameInvBatchID && props?.touched.headerNameInvBatchID && <span className={props?.styles?.errorMsg}>Header must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="headerNamePurchaseOrder"
                      name="headerNamePurchaseOrder"
                      value={props?.values?.headerNamePurchaseOrder}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.headerNamePurchaseOrder && props?.touched.headerNamePurchaseOrder && <span className={props?.styles?.errorMsg}>Header must be at most 50 characters</span>}
                  </div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    Alt Header 1
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altFirstHeaderShipReq"
                      name="altFirstHeaderShipReq"
                      value={props?.values?.altFirstHeaderShipReq}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altFirstHeaderShipReq && props?.touched.altFirstHeaderShipReq && <span className={props?.styles?.errorMsg}>Alt Header 1 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altFirstHeaderReceiptDate"
                      name="altFirstHeaderReceiptDate"
                      value={props?.values?.altFirstHeaderReceiptDate}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altFirstHeaderReceiptDate && props?.touched.altFirstHeaderReceiptDate && <span className={props?.styles?.errorMsg}>Alt Header 1 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altFirstHeaderShipTo"
                      name="altFirstHeaderShipTo"
                      value={props?.values?.altFirstHeaderShipTo}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altFirstHeaderShipTo && props?.touched.altFirstHeaderShipTo && <span className={props?.styles?.errorMsg}>Alt Header 1 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altFirstHeaderPO"
                      name="altFirstHeaderPO"
                      value={props?.values?.altFirstHeaderPO}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altFirstHeaderPO && props?.touched.altFirstHeaderPO && <span className={props?.styles?.errorMsg}>Alt Header 1 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altFirstHeaderItem"
                      name="altFirstHeaderItem"
                      value={props?.values?.altFirstHeaderItem}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altFirstHeaderItem && props?.touched.altFirstHeaderItem && <span className={props?.styles?.errorMsg}>Alt Header 1 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altFirstHeaderQty"
                      name="altFirstHeaderQty"
                      value={props?.values?.altFirstHeaderQty}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altFirstHeaderQty && props?.touched.altFirstHeaderQty && <span className={props?.styles?.errorMsg}>Alt Header 1 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altFirstHeaderUOM"
                      name="altFirstHeaderUOM"
                      value={props?.values?.altFirstHeaderUOM}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altFirstHeaderUOM && props?.touched.altFirstHeaderUOM && <span className={props?.styles?.errorMsg}>Alt Header 1 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altFirstHeaderDescription"
                      name="altFirstHeaderDescription"
                      value={props?.values?.altFirstHeaderDescription}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altFirstHeaderDescription && props?.touched.altFirstHeaderDescription && <span className={props?.styles?.errorMsg}>Alt Header 1 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altFirstHeaderRoute"
                      name="altFirstHeaderRoute"
                      value={props?.values?.altFirstHeaderRoute}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altFirstHeaderRoute && props?.touched.altFirstHeaderRoute && <span className={props?.styles?.errorMsg}>Alt Header 1 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altFirstHeaderStop"
                      name="altFirstHeaderStop"
                      value={props?.values?.altFirstHeaderStop}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altFirstHeaderStop && props?.touched.altFirstHeaderStop && <span className={props?.styles?.errorMsg}>Alt Header 1 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altFirstHeaderWRHS"
                      name="altFirstHeaderWRHS"
                      value={props?.values?.altFirstHeaderWRHS}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altFirstHeaderWRHS && props?.touched.altFirstHeaderWRHS && <span className={props?.styles?.errorMsg}>Alt Header 1 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altFirstHeaderInvBatchID"
                      name="altFirstHeaderInvBatchID"
                      value={props?.values?.altFirstHeaderInvBatchID}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altFirstHeaderInvBatchID && props?.touched.altFirstHeaderInvBatchID && <span className={props?.styles?.errorMsg}>Alt Header 1 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altFirstHeaderPurchaseOrder"
                      name="altFirstHeaderPurchaseOrder"
                      value={props?.values?.altFirstHeaderPurchaseOrder}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altFirstHeaderPurchaseOrder && props?.touched.altFirstHeaderPurchaseOrder && <span className={props?.styles?.errorMsg}>Alt Header 1 must be at most 50 characters</span>}
                  </div>
                  <div
                    className={`${props?.styles?.gridItem} ${props?.styles?.headerBlock}`}
                  >
                    Alt Header 2
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altSecondHeaderShipReq"
                      name="altSecondHeaderShipReq"
                      value={props?.values?.altSecondHeaderShipReq}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altSecondHeaderShipReq && props?.touched.altSecondHeaderShipReq && <span className={props?.styles?.errorMsg}>Alt Header 2 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altSecondHeaderReceiptDate"
                      name="altSecondHeaderReceiptDate"
                      value={props?.values?.altSecondHeaderReceiptDate}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                     {props?.errors.altSecondHeaderReceiptDate && props?.touched.altSecondHeaderReceiptDate && <span className={props?.styles?.errorMsg}>Alt Header 2 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altSecondHeaderShipTo"
                      name="altSecondHeaderShipTo"
                      value={props?.values?.altSecondHeaderShipTo}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altSecondHeaderShipTo && props?.touched.altSecondHeaderShipTo && <span className={props?.styles?.errorMsg}>Alt Header 2 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altSecondHeaderPO"
                      name="altSecondHeaderPO"
                      value={props?.values?.altSecondHeaderPO}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altSecondHeaderPO && props?.touched.altSecondHeaderPO && <span className={props?.styles?.errorMsg}>Alt Header 2 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altSecondHeaderItem"
                      name="altSecondHeaderItem"
                      value={props?.values?.altSecondHeaderItem}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altSecondHeaderItem && props?.touched.altSecondHeaderItem && <span className={props?.styles?.errorMsg}>Alt Header 2 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altSecondHeaderQty"
                      name="altSecondHeaderQty"
                      value={props?.values?.altSecondHeaderQty}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altSecondHeaderQty && props?.touched.altSecondHeaderQty && <span className={props?.styles?.errorMsg}>Alt Header 2 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altSecondHeaderUOM"
                      name="altSecondHeaderUOM"
                      value={props?.values?.altSecondHeaderUOM}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altSecondHeaderUOM && props?.touched.altSecondHeaderUOM && <span className={props?.styles?.errorMsg}>Alt Header 2 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altSecondHeaderDescription"
                      name="altSecondHeaderDescription"
                      value={props?.values?.altSecondHeaderDescription}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altSecondHeaderDescription && props?.touched.altSecondHeaderDescription && <span className={props?.styles?.errorMsg}>Alt Header 2 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altSecondHeaderRoute"
                      name="altSecondHeaderRoute"
                      value={props?.values?.altSecondHeaderRoute}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                     {props?.errors.altSecondHeaderRoute && props?.touched.altSecondHeaderRoute && <span className={props?.styles?.errorMsg}>Alt Header 2 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altSecondHeaderStop"
                      name="altSecondHeaderStop"
                      value={props?.values?.altSecondHeaderStop}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altSecondHeaderStop && props?.touched.altSecondHeaderStop && <span className={props?.styles?.errorMsg}>Alt Header 2 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altSecondHeaderWRHS"
                      name="altSecondHeaderWRHS"
                      value={props?.values?.altSecondHeaderWRHS}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                     {props?.errors.altSecondHeaderWRHS && props?.touched.altSecondHeaderWRHS && <span className={props?.styles?.errorMsg}>Alt Header 2 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altSecondHeaderInvBatchID"
                      name="altSecondHeaderInvBatchID"
                      value={props?.values?.altSecondHeaderInvBatchID}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altSecondHeaderInvBatchID && props?.touched.altSecondHeaderInvBatchID && <span className={props?.styles?.errorMsg}>Alt Header 2 must be at most 50 characters</span>}
                  </div>
                  <div className={props?.styles?.gridItem}>
                    <InputText
                      id="altSecondHeaderPurchaseOrder"
                      name="altSecondHeaderPurchaseOrder"
                      value={props?.values?.altSecondHeaderPurchaseOrder}
                      onChange={props?.changeEvent}
                      className={`${props?.styles.inputField} ${props?.styles.formControl} ${props?.styles.tableInput}`}
                      disabled={props?.isReadonly}
                    />
                    {props?.errors.altSecondHeaderPurchaseOrder && props?.touched.altSecondHeaderPurchaseOrder && <span className={props?.styles?.errorMsg}>Alt Header 2 must be at most 50 characters</span>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 text-right">
            <Button
              label="Previous"
              type="button"
              size="large"
              icon="pi pi-angle-left"
              iconPos="left"
              outlined
              onClick={()=>changeTab(1)}
              className={props?.styles.secondaryBtn}
            />
             {showFtp &&<Button
          label="Next"
          type="button"
          size="large"
          icon="pi pi-angle-right"
          iconPos="right"
          outlined
          className={props?.styles.secondaryBtn}
          onClick={()=>changeTab(3)}
        />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapFileInfoForm;
