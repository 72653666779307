import { useState } from "react"
import FunctionList from "./functionList"
import FunctionForm from "./functionForm"

const FunctionSetUp = () => {
    const [showfucntionList, setShowFunctionList] = useState(true);
    const [formStateData, setFormStateData] = useState("new")
    const [ functionIdData, setFunctionIdData] = useState(0)

    const changeView = (value,formState,functionId) => {
        setShowFunctionList(value);
        setFormStateData(formState);
        setFunctionIdData(functionId);
    }
    return (
        <>
         {showfucntionList ? <FunctionList alterComponent={changeView}/> : <FunctionForm alterComponent={changeView} formState={formStateData} functionId={functionIdData}/>}
        </>
    )
}

export default FunctionSetUp