import {api} from "../api/api";
const getFunctionConfigurationDetails = async (id) => {
  try {
    const response = await api.get(
      `/api/Functions/EDIXMLMappings?functionConfigurationId=${id}`
    );
    return response.data?.result?.result || [];
  } catch (error) {
    throw new Error(error?.response?.data?.title);
  }
};

export default getFunctionConfigurationDetails;
